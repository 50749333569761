<template>
  <div class="flex flex-col gap-5">
    <InputEl
      v-model="specificPlantSize"
      label="Spezifische Anlagengröße"
      input-type="integer"
      placeholder="Bitte eingeben"
      class="w-full"
      suffix="ha"
    />
    <InputEl
      v-model="dcAcRatio"
      label="DV/AC Ratio"
      input-type="integer"
      placeholder="Bitte eingeben"
      class="w-full"
    />
    <InputEl
      v-model="inverterEfficiency"
      label="Wechselrichter-Wirkungsgrad"
      input-type="integer"
      placeholder="0"
      class="w-full"
      suffix="°"
    />
    <InputEl
      v-model="performanceRatio"
      label="Performance Ratio"
      input-type="integer"
      placeholder="Bitte eingeben"
      suffix="°"
      class="w-full"
    />
    <InputEl
      v-model="mountingSlopeDegree"
      label="Neigungswinkel durch Aufständerung"
      input-type="integer"
      placeholder="Bitte eingeben"
      class="w-full"
      suffix="°"
    />
  </div>
</template>

<script setup>
import InputEl from '@/components/input/InputEl.vue';
import { ref, watch } from 'vue';

const emit = defineEmits(['update']);

defineExpose({ populateInputs });

const specificPlantSize = ref();
const dcAcRatio = ref();
const inverterEfficiency = ref();
const performanceRatio = ref();
const mountingSlopeDegree = ref();

watch(
  [
    specificPlantSize,
    dcAcRatio,
    inverterEfficiency,
    performanceRatio,
    mountingSlopeDegree,
  ],
  () => {
    emit('update', {
      specific_plant_size_mw_ha: specificPlantSize.value,
      dc_ac_ratio: dcAcRatio.value,
      inverter_efficiency: inverterEfficiency.value,
      performance_ratio: performanceRatio.value,
      mounting_slope_degree: mountingSlopeDegree.value,
    });
  },
);

function populateInputs(potentialAnalysis) {
  specificPlantSize.value = potentialAnalysis.specific_plant_size_mw_ha;
  dcAcRatio.value = potentialAnalysis.dc_ac_ratio;
  inverterEfficiency.value = potentialAnalysis.inverter_efficiency;
  performanceRatio.value = potentialAnalysis.performance_ratio;
  mountingSlopeDegree.value = potentialAnalysis.mounting_slope_degree;
}
</script>
