<template>
  <div class="absolute flex left-5 z-[100] h-10">
    <div class="w-[360px] flex gap-[5px]">
      <div class="standard-elevation-2 flex rounded-md gap-[5px]">
        <ToHome
          class="z-10"
          :class="{ 'rounded-r-none': !baseStore.getShowScenarioSelector }"
        />

        <IconWrapper
          v-show="!baseStore.getShowScenarioSelector"
          icon="pin_drop"
          fill="text-color1"
          :class="
            baseStore.getShowScenarioSelector
              ? '-ml-[45px] rounded-md'
              : '-ml-[5px] rounded-r-md'
          "
          class="transition-all bg-color2 h-10 px-2 cursor-pointer"
          icon-type="round"
          @click="baseStore.setShowScenarioSelector(true)"
        />
      </div>

      <ButtonEl
        data-test="main-menu-toggle"
        icon="segment"
        :variant="toggleVariant('mainMenu')"
        :color="toggleColor('mainMenu')"
        icon-type="round"
        class="rounded-[8px] h-10 standard-elevation-2"
        @click="toggleMainMenu"
      />

      <SearchBar />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import ToHome from '@/apps/usecase-1/map/control-options/components/ToHome.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import SearchBar from '@/apps/usecase-1/map/control-options/search/SearchBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { useBaseStore } from '@/apps/basemodule/base-store';

const store = useStore();
const baseStore = useBaseStore();

onMounted(() => {
  if (store.getters.getMainMenuShow) {
    store.commit('SET_MAIN_MENU_SHOW', false);
  }
});

const getMainMenuShow = computed(() => store.getters.getMainMenuShow);

function toggleMainMenu() {
  store.commit('SET_MAIN_MENU_SHOW', !store.getters.getMainMenuShow);
}

function toggleVariant(type) {
  if (type === 'mainMenu') {
    return getMainMenuShow.value ? 'primary' : 'primary';
  }
}

function toggleColor(type) {
  if (type === 'mainMenu') {
    return getMainMenuShow.value ? 'color1' : 'color2';
  }
}
</script>

<style scoped lang="scss"></style>
