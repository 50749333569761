<template>
  <div class="flex flex-col gap-5">
    <div class="flex flex-col gap-5 w-1/2">
      <InputEl
        v-model="minArea"
        label="Zusammenhängende Mindestfläche"
        input-type="integer"
        placeholder="Bitte eingeben"
        class="w-full"
        suffix="ha"
      />
      <InputEl
        v-model="maxEstatesOnArea"
        label="Maximale Anzahl Flurstücke auf Fläche"
        input-type="integer"
        placeholder="Bitte eingeben"
        class="w-full"
      />
    </div>

    <div
      class="grid grid-cols-2 gap-5 standard-elevation-0-dark rounded-[8px] p-5"
    >
      <div class="flex flex-col gap-5">
        <CheckboxEl v-model="customSlopeSetting">
          <span>Hangneigung</span>
        </CheckboxEl>

        <div class="standard-elevation-0-dark rounded-[6px] gap-5 p-5">
          <CircleDegreeMeasure v-model="slopeDegreeComputed" />
        </div>
        <div class="flex gap-5">
          <InputEl
            v-model="minSlopeDegree"
            input-type="integer"
            placeholder="Bitte eingeben"
            :rules="{ min: 0, max: 360 }"
            :positive-only="true"
            class="w-full"
            suffix="°"
          />
          <InputEl
            v-model="maxSlopeDegree"
            input-type="integer"
            placeholder="Bitte eingeben"
            :rules="{ min: minSlopeDegree + 1, max: 360 }"
            :positive-only="true"
            suffix="°"
            class="w-full"
          />
        </div>
      </div>

      <div class="flex flex-col gap-5">
        <CheckboxEl v-model="customOrientationSetting">
          <span>Flächenausrichtung</span>
          <ToolTip class="ml-2">
            <template #text>
              {{
                `Die Eingabe der Ausrichtung erfolgt in Grad - eine
                Südausrichtung von SO bis SW entspräche dem Bereich von 135°
                bis 225°. Achtung: die Datengrundlage der topografischen
                Daten liegen in einer Auflösung von 100x100m vor. Diese
                Auflösung kann zu Unschärfen hinsichtlich der Hangneigung und
                 -Ausrichtung führen.
              `
              }}
            </template>
          </ToolTip>
        </CheckboxEl>

        <div class="standard-elevation-0-dark rounded-[6px] gap-5 p-5">
          <CircleDegreeMeasure v-model="orientationDegreeComputed" />
        </div>
        <div class="flex gap-5">
          <InputEl
            v-model="minOrientationDegree"
            input-type="integer"
            placeholder="Bitte eingeben"
            :rules="{ min: 0, max: 360 }"
            :positive-only="true"
            class="w-full"
            suffix="°"
          />
          <InputEl
            v-model="MaxOrientationDegree"
            input-type="integer"
            placeholder="Bitte eingeben"
            :rules="{ min: minOrientationDegree + 1, max: 360 }"
            :positive-only="true"
            suffix="°"
            class="w-full"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import InputEl from '@/components/input/InputEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import { computed, ref, watch } from 'vue';
import CircleDegreeMeasure from '@/apps/usecase-2/potential-analysis/components/CircleDegreeMeasure.vue';

const emit = defineEmits(['update']);

defineExpose({ populateInputs });

const minArea = ref();
const maxEstatesOnArea = ref();
const minOrientationDegree = ref(45);
const MaxOrientationDegree = ref(90);
const minSlopeDegree = ref(45);
const maxSlopeDegree = ref(90);

const slopeDegreeComputed = computed(() => {
  return [minSlopeDegree.value, maxSlopeDegree.value];
});

const orientationDegreeComputed = computed(() => {
  return [minOrientationDegree.value, MaxOrientationDegree.value];
});

const customOrientationSetting = ref(true);
const customSlopeSetting = ref(true);

watch(
  [
    minArea,
    maxEstatesOnArea,
    maxSlopeDegree,
    minOrientationDegree,
    MaxOrientationDegree,
  ],
  () => {
    emit('update', {
      min_area_ha: minArea.value,
      max_count_parcels: maxEstatesOnArea.value,
      slope_degree_value_greater_equal_than: minSlopeDegree.value,
      slope_degree_value_smaller_equal_than: maxSlopeDegree.value,
      orientation_value_greater_equal_than: minOrientationDegree.value,
      orientation_value_smaller_equal_than: MaxOrientationDegree.value,
    });
  },
);

// reset values to max range if no setting is required
watch([customOrientationSetting, customSlopeSetting], () => {
  if (!customSlopeSetting.value) {
    minSlopeDegree.value = 0;
    maxSlopeDegree.value = 90;
  }
  if (!customOrientationSetting.value) {
    minOrientationDegree.value = 0;
    MaxOrientationDegree.value = 360;
  }
});

function populateInputs(potentialAnalysis) {
  minArea.value = potentialAnalysis.min_area_ha;
  maxEstatesOnArea.value = potentialAnalysis.max_count_parcels;
  minSlopeDegree.value =
    potentialAnalysis.slope_degree_value_greater_equal_than;
  maxSlopeDegree.value =
    potentialAnalysis.slope_degree_value_smaller_equal_than;
  minOrientationDegree.value =
    potentialAnalysis.orientation_value_greater_equal_than;
  MaxOrientationDegree.value =
    potentialAnalysis.orientation_value_smaller_equal_than;
}
</script>
