<template>
  <div v-if="!buttonOnly" class="flex flex-col gap-5">
    <div
      class="min-h-[80px] cursor-pointer border-2 border-dashed border-line-disabled-color1 bg-active-area p-2.5 rounded-[6px] flex items-center justify-center transition-all duration-200"
      :class="{
        'bg-blue-100': isDragging && !isMaxFilesReached,
      }"
      @dragover.prevent
      @dragenter="handleDragEnter"
      @dragleave="handleDragLeave"
      @drop="handleDrop"
    >
      <label class="flex cursor-pointer items-center gap-2.5 text-color1">
        <IconWrapper :size="16" :icon="IconUploadFile" />
        <span class="body-1 text-color1">Datei hierhin ziehen oder</span>
        <input
          ref="fileInput"
          type="file"
          class="hidden"
          :multiple="maxFiles > 1"
          :disabled="isMaxFilesReached"
          @change="handleFileChange"
        />
        <ButtonEl
          :disabled="isMaxFilesReached"
          :text="buttonText"
          variant="secondary"
          :icon="buttonIcon"
          :icon-right="buttonIconRight"
          color="color2"
          @click="openFileDialog"
        />
      </label>
    </div>
    <!-- specs -->
    <div
      class="flex justify-between items-center text-title-neutral body-2 py-2.5 gap-5"
    >
      <span
        >{{
          (allowedFileTypes?.length > 1
            ? 'Zulässige Dateiformate'
            : 'Zulässiges Dateiformat') + ':'
        }}
        {{
          Array.isArray(allowedFileTypes) &&
          allowedFileTypes?.map((type) => `.${type}`).join(', ')
        }}</span
      >
      <span>Maximale Dateigröße: {{ allowedFileSize }}</span>
    </div>
    <ul v-if="files.length" class="pl-0">
      <li
        v-for="(file, index) in files"
        :key="index"
        class="flex items-center justify-between p-2 first:border-y border-b border-gray-200 gap-2"
      >
        <div class="flex items-center gap-0.5 text-neutral">
          <IconWrapper :size="20" :icon="IconFileCopy" />
          <span class="body-2 pt-1">{{ file.name }}</span>
        </div>
        <div class="flex items-center gap-2">
          <span
            class="text-white subtitle-3 bg-disabled-neutral p-1 pb-0.5 rounded-[2px] mt-[1px]"
            >{{ (file.size / (1024 * 1024)).toFixed(0) }} MB</span
          >
          <IconWrapper
            icon="delete"
            fill="text-color1"
            class="cursor-pointer"
            @click="removeFile(index)"
          />
        </div>
      </li>
    </ul>
  </div>

  <ButtonEl
    v-else
    :text="buttonText"
    variant="secondary"
    :icon="buttonIcon"
    :icon-right="buttonIconRight"
    color="color2"
    @click="openFileDialog"
  />
  <input
    ref="fileInput"
    type="file"
    class="hidden"
    :multiple="maxFiles > 1"
    @change="handleFileChange"
  />
</template>

<script setup>
import { ref, watch } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconUploadFile from '@/assets/icons/custom/misc/IconUploadFile.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import IconFileCopy from '@/assets/icons/custom/misc/IconFileCopy.vue';

import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  maxFiles: {
    type: Number,
    default: 1,
  },
  allowedFileSize: {
    type: String,
    default: '5 MB',
  },
  allowedFileTypes: {
    type: Array,
    default: () => ['csv'],
  },
  buttonText: {
    type: String,
    default: 'Datei wählen',
  },
  buttonIcon: {
    type: String,
    default: null,
  },
  buttonIconRight: {
    type: Boolean,
    default: true,
  },
  buttonOnly: {
    type: Boolean,
    default: false,
  },
  successToast: {
    type: String,
    default: null,
  },
});

const fileInput = ref(null);
const files = ref([]);
const isDragging = ref(false);
const emit = defineEmits(['files-selected']);

const isMaxFilesReached = ref(false);

function handleFileChange(event) {
  const selectedFiles = Array.from(event.target.files);
  processFiles(selectedFiles);
}

function handleDrop(event) {
  event.preventDefault();
  event.stopPropagation();
  if (isMaxFilesReached.value) {
    return;
  }
  const droppedFiles = Array.from(event.dataTransfer.files);
  processFiles(droppedFiles);
  isDragging.value = false;
}

function processFiles(selectedFiles) {
  const validFiles = [];
  const [maxFileSize, sizeUnit] = parseFileSize(props.allowedFileSize);

  selectedFiles.forEach((file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const fileSizeInSpecifiedUnit = convertFileSize(file.size, sizeUnit);

    if (!props.allowedFileTypes?.includes(fileExtension)) {
      createToast('Dateiformat nicht zulässig');
      return;
    }

    if (fileSizeInSpecifiedUnit > maxFileSize) {
      createToast(
        'Dateigröße überschreitet das Limit von ' + props.allowedFileSize,
      );
      return;
    }

    if (props.successToast) {
      createToast(props.successToast, 'success');
    }

    validFiles.push(file);
  });

  files.value = [...files.value, ...validFiles].slice(0, props.maxFiles);
  emit('files-selected', files.value);
  updateMaxFilesReached();
}

function createToast(message, color = 'warning') {
  store.commit('layout/SHOWTOAST', {
    message,
    color,
  });
}

function parseFileSize(fileSize) {
  const [size, unit] = fileSize.split(' ');
  return [parseFloat(size), unit];
}

function convertFileSize(size, unit) {
  switch (unit) {
    case 'KB':
      return size / 1024;
    case 'MB':
      return size / (1024 * 1024);
    case 'GB':
      return size / (1024 * 1024 * 1024);
    default:
      return size;
  }
}

function handleDragEnter() {
  if (!isMaxFilesReached.value) {
    isDragging.value = true;
  }
}

function handleDragLeave() {
  isDragging.value = false;
}

function openFileDialog() {
  fileInput.value.click();
}

function removeFile(index) {
  files.value.splice(index, 1);
  emit('files-selected', files.value);
  updateMaxFilesReached();
}

function updateMaxFilesReached() {
  isMaxFilesReached.value = files.value.length >= props.maxFiles;
}

watch(files, () => {
  updateMaxFilesReached();
});
</script>
