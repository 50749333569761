export const tabs = [
  {
    title: 'Name & Gebietsauswahl',
    icon: 'zoom_in_map',
    iconType: 'outlined',
  },
  {
    title: 'PV-Auslegung',
    icon: 'settings_applications',
    iconType: 'outlined',
  },
  {
    title: 'Flächenkulisse',
    icon: 'settings_applications',
    iconType: 'outlined',
  },
  {
    title: 'Netzanschluss',
    icon: 'settings_applications',
    iconType: 'outlined',
  },
  {
    title: 'Restriktionen',
    icon: 'deselect',
    iconType: 'outlined',
  },
  {
    title: 'Besondere Flächenqualifizierung',
    icon: 'layers',
    iconType: 'outlined',
  },
];

export const tableHeadersPotentials = [
  {
    title: 'Name',
    key: 'title',
    width: '150px',
  },
  {
    title: 'Erstellt am',
    key: 'created_date',
    sortable: false,
    width: '125px',
  },
  {
    title: 'Erstellt am',
    key: 'updated_date',
    sortable: false,
    width: '125px',
  },
  {
    title: 'Status',
    key: 'progress',
    sortable: false,
    width: '125px',
  },
  {
    title: 'Aktionen',
    key: 'functions',
    align: 'end',
    sortable: false,
    width: '125px',
  },
];
