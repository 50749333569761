import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import layers from './layer-config';
import { layerPanelConfig } from '@/apps/usecase-1/map/layer-panel/layer-panel-config';
import {
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
} from './layer-config/geographical-level';

const AGGREGATION_LAYERS = [
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
];

export const useMapStore = defineStore('Map', {
  state: () => ({
    scenarioSelected: null,
    showInfoPanel: false,
    showSlider: false,
    year: null,
    layerConfigs: cloneDeep(layers),
    layerPanelConfig: cloneDeep(layerPanelConfig),
    FlyTo: {
      zoom: 12,
      lng: 10.4515,
      lat: 51.1656,
      essential: true,
      maxZoom: 16.9,
    },
    metaDataLayers: {},
    filters: {},
    satelliteIsActive: false,
    containerHeight: 500,
    refreshFeature: false,
    aggregationLevel: null,
    aggregationStyle: 'default',
    customMapConfig: [],
    activeLayerToggleSections: {},
  }),
  actions: {
    changeLayerVisibility(payload) {
      payload.onLayers.forEach((i) => {
        if (this.layerConfigs[i] !== undefined) {
          this.layerConfigs[i].visible = payload.active;
        } else {
          console.warn(`Layer config for index ${i} is undefined.`);
        }
      });
    },

    changeLayerState(payload) {
      payload.layerKeys.forEach((i) => {
        this.layerConfigs[i].layoutState = payload.layerState;
      });
    },
    addFilter(payload) {
      const filters = { ...this.filters };
      payload.layerKeys.forEach((i) => {
        let filterConfig = filters[i] || {};
        filterConfig[payload.filter.id] = payload.filter.filter;
        filters[i] = filterConfig;
      });
      this.filters = filters;
    },
    setShowInfoPanel(show) {
      this.showInfoPanel = show;
    },
    showYearSlider(show) {
      this.showSlider = show;
    },
    setYear(year) {
      this.year = year;
    },
    toggleSatellite() {
      this.satelliteIsActive = !this.satelliteIsActive;
    },
    setCoordinates: (state, payload) => {
      const zoom = payload.zoom || 12;
      state.FlyTo.lng = payload.lng;
      state.FlyTo.lat = payload.lat;
      state.FlyTo.zoom = zoom;
    },
    setContainerHight(payload) {
      this.containerHeight = payload;
    },
    resetConfigs() {
      this.layerPanelConfig = cloneDeep(layerPanelConfig);
      this.layerConfigs = cloneDeep(layers);
      this.activeLayerToggleSections = {};
    },
    setInfoPanelVisibility(show) {
      this.showInfoPanel = show;
    },
    setLayerMetaData(layerId, metaData) {
      this.metaDataLayers[layerId] = metaData;
    },
    setAggregationLevel(layer) {
      for (const layer of AGGREGATION_LAYERS) {
        this.layerConfigs[layer].visible = false;
      }
      if (layer) {
        this.layerConfigs[layer].visible = true;
        // set layer state accordingly
        this.changeLayerState({
          layerKeys: [layer],
          layerState: this.aggregationStyle,
        });
      } else {
        this.setAggregationStyle('default');
      }
      this.aggregationLevel = layer;
    },
    setAggregationStyle(style) {
      this.aggregationStyle = style;
    },
    setActiveToggle(layerState, toggleId, active) {
      if (typeof this.activeLayerToggleSections[layerState] === 'undefined') {
        this.activeLayerToggleSections[layerState] = new Set();
      }
      if (active) this.activeLayerToggleSections[layerState].add(toggleId);
      else delete this.activeLayerToggleSections[layerState].delete(toggleId);
    },
    /**
     * change default layers and states on next map navigation
     * @param  {array} config Array of Obj containing layer and state
     */
    configureInitialMapState(config) {
      this.customMapConfig = config;
    },
  },
  getters: {
    getLegendEntries: (state) => {
      const vis = [];
      Object.entries(state.layerConfigs).forEach((item) => {
        const e = item[1];
        if (e.visible && e.legend) {
          const legend = e.legend[e.layoutState];
          if (legend) vis.push(legend);
        }
      });
      return vis;
    },
    getLayerStates: (state) =>
      Object.keys(state.layerConfigs).map((e) => ({
        layer: e,
        state: state.layerConfigs[e].layoutState,
        visible: state.layerConfigs[e].visible,
      })),
    getLayersToClick: (state) => {
      const layers = [];
      Object.entries(state.layerConfigs).forEach((item) => {
        if (item[1].sidebar || item[1].onClick) {
          layers.push(item[0]);
        }
      });
      return layers;
    },
    getLayers: (state) => {
      return Object.keys(state.layerConfigs);
    },
    getLayersVisible: (state) => {
      const layersVisible = [];
      for (const [key, value] of Object.entries(state.layerConfigs)) {
        if (value.visible) layersVisible.push(key);
      }
      return layersVisible;
    },
    getAppliedFilters: (state) => {
      const appliedFilters = {};
      for (const layer of Object.keys(state.filters)) {
        const filter = Object.values(state.filters[layer]);
        appliedFilters[layer] = null;
        if (filter.length === 1) appliedFilters[layer] = filter[0];
        if (filter.length > 1) appliedFilters[layer] = ['all', ...filter];
      }
      return appliedFilters;
    },
    getContainerHeight: (state) => state.containerHeight,
  },
});
