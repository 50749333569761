import { createRouter, createWebHistory } from 'vue-router';
import { useUsers } from '@/apps/auth/users-store';
import { usePageTransitionStore } from '@/composables/usePageTransitionStore';
import ProjectListWrapper from '@/apps/usecase-1/projects/ProjectListWrapper.vue';
import ProjectCreateWrapper from '@/apps/usecase-1/projects/project-create/ProjectCreateWrapper.vue';
import ProjectDetailsWrapper from '@/apps/usecase-1/projects/project-result-detail/ProjectDetailsWrapper.vue';
import ProjectSelectDialog from '@/apps/usecase-1/map/ProjectSelectDialog.vue';
import MapView1 from '@/apps/usecase-1/map/MapView1.vue';
import ScenarioDemand from '@/apps/usecase-1/projects/project-result-detail/ScenarioDemand.vue';
import ProjectOverview from '@/apps/usecase-1/projects/project-result-detail/ProjectOverview.vue';
import ProjectRenewableGeneration from '@/apps/usecase-1/projects/project-result-detail/ProjectRenewableGeneration.vue';
import ScenarioPowerBalance from '@/apps/usecase-1/projects/project-result-detail/ScenarioPowerBalance.vue';
import ScenarioGridPlanning from '@/apps/usecase-1/projects/project-result-detail/ScenarioGridPlanning.vue';
import AreaEvaluation from '@/apps/usecase-1/projects/project-result-detail/AreaEvaluation.vue';
import ScenarioComparison from '@/apps/usecase-1/projects/project-result-detail/ScenarioComparison.vue';
import ProjectReports from '@/apps/usecase-1/projects/project-result-detail/ProjectReports.vue';
import PotentialAnalysisPage from '@/apps/usecase-2/potential-analysis/PotentialAnalysisPage.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/apps/auth/LoginPage.vue'),
      meta: {
        layout: 'Auth',
        title: 'Login',
        transitionName: 'fade',
      },
    },
    {
      path: '/uc1',
      name: 'UseCase1',
      component: () =>
        import('@/apps/usecase-1/start-page/UseCase1StartPage.vue'),
      meta: {
        requiresAuth: true,
        title: 'Energiewendepartner',
      },
      children: [
        {
          path: 'projects',
          name: 'UseCase1ProjectPage',
          meta: {
            requiresAuth: true,
            title: 'Projekte',
          },
          children: [
            {
              path: '',
              name: 'UseCase1ProjectList',
              component: ProjectListWrapper,
              props: true,
              meta: {
                requiresAuth: true,

                transitionName: 'fade',
              },
            },
            {
              path: 'create',
              name: 'UseCase1ProjectCreate',
              component: ProjectCreateWrapper,
              meta: {
                requiresAuth: true,
                title: 'Projekt Erstellen',
                transitionName: 'fade',
              },
            },
            {
              path: 'project-result-detail-:projectId',
              name: 'UseCase1ProjectResultDetailPage',
              component: ProjectDetailsWrapper,
              props: true,
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: 'overview-:scenarioId',
                  name: 'UseCase1ProjectResultOverview',
                  component: ProjectOverview,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Übersicht Projektgebiet',
                  },
                },
                {
                  path: 'demand-generation-:scenarioId',
                  name: 'UseCase1ProjectScenarioDemand',
                  component: ScenarioDemand,
                  props: true,
                  meta: {
                    title:
                      'Projekt Auswertung: Bestandsanalyse und Nachfrageentwicklung',
                  },
                },
                {
                  path: 'renewable-generation-:scenarioId',
                  name: 'UseCase1ProjectProjectRenewableGeneration',
                  component: ProjectRenewableGeneration,
                  props: true,
                  meta: {
                    title: 'EE-Erzeugung',
                  },
                },
                {
                  path: 'power-balance-:scenarioId',
                  name: 'UseCase1ProjectScenarioPowerBalance',
                  component: ScenarioPowerBalance,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Mengen- und Leistungsbilanz',
                  },
                },
                {
                  path: 'grid-planning-:scenarioId',
                  name: 'UseCase1ProjectScenarioGridPlanning',
                  component: ScenarioGridPlanning,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Netzplanung',
                  },
                },
                {
                  path: 'area-evaluation-:scenarioId',
                  name: 'UseCase1AreaEvaluation',
                  component: AreaEvaluation,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Gebietsauswertung',
                  },
                },
                {
                  path: 'scenario-comparison-:scenarioId',
                  name: 'UseCase1ScenarioComparison',
                  component: ScenarioComparison,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Szenarienvergleich',
                  },
                },
                {
                  path: 'project-reports-:scenarioId',
                  name: 'UseCase1ProjectReports',
                  component: ProjectReports,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Berichte',
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'assumptions-scenario',
          name: 'AssumptionsScenario',
          component: () =>
            import(
              '@/apps/usecase-1/assumptions-scenario/AssumptionsScenarioPage.vue'
            ),
          meta: {
            requiresAuth: true,
            title: 'Nachfrage modellieren',
            transitionName: 'fade',
          },
        },
        {
          path: 'assumptions-general',
          name: 'AssumptionsGeneral',
          component: () =>
            import(
              '@/apps/usecase-1/assumptions-general/AssumptionsGeneralPage.vue'
            ),
          meta: {
            requiresAuth: true,
            title: 'Allgemeine Annahmen',
            transitionName: 'fade',
          },
        },
        {
          path: 'map',
          name: 'mapProjectSelection',
          component: ProjectSelectDialog,
          meta: {
            requiresAuth: true,
            layout: 'Map',
            hide_footer: 1,
            title: 'Kartenprojekt auswählen',
            transitionName: 'fade',
          },
        },
        {
          path: 'map/:projectId/:scenarioId',
          name: 'mapView1',
          component: MapView1,
          meta: {
            requiresAuth: true,
            layout: 'Map',
            hide_footer: 1,
            title: 'Kartenansicht',
            transitionName: 'fade',
          },
        },
      ],
    },
    {
      path: '/uc2',
      name: 'UseCase2',
      component: () =>
        import('@/apps/usecase-2/start-page/UseCase2StartPage.vue'),
      meta: {
        requiresAuth: true,
        title: 'Optimierte Geoallokation',
      },
      children: [
        {
          path: 'potential-analysis',
          name: 'UseCase2PotentialPage',
          component: PotentialAnalysisPage,
          meta: {
            requiresAuth: true,
            title: 'Nachfrage modellieren',
            transitionName: 'fade',
          },
        },
      ],
    },
    {
      path: '/forgot-password',
      name: 'ForgotPage',
      component: () => import('@/apps/auth/ForgotPage.vue'),
      meta: {
        layout: 'Auth',
        title: 'Passwort vergessen',
        transitionName: 'fade',
      },
    },
    {
      path: '/reset-password',
      name: 'ResetPage',
      component: () => import('@/apps/auth/ResetPage.vue'),
      meta: {
        layout: 'Auth',
        title: 'Passwort zurücksetzen',
        transitionName: 'fade',
      },
    },
    {
      path: '/',
      name: 'landingURL',
      component: () => import('@/apps/landing-page/LandingPage.vue'),
      meta: {
        requiresAuth: true,
        title: 'Startseite',
      },
    },
    {
      path: '/registration/:token',
      name: 'dataMask',
      component: () => import('@/apps/auth/UserRegistration.vue'),
      meta: {
        layout: 'Auth',
        title: 'Registrierung',
        transitionName: 'fade',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/apps/profile/ProfilePage.vue'),
      meta: {
        requiresAuth: true,
        title: 'Mein Profil',
        transitionName: 'fade',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'error',
      component: () => import('@/apps/NotFoundPage.vue'),
      meta: {
        hide_footer: 1,
        layout: 'Plain',
        title: 'Seite nicht gefunden',
        transitionName: 'fade',
      },
    },
  ],
});
/**
 * Before each route updated
 */

router.beforeEach(async (to, from, next) => {
  const userStore = useUsers();
  const transitionStore = usePageTransitionStore();

  // Set page transition
  transitionStore.setTransitionName(to, from);

  // Fetch client hash
  await userStore.getClientHash();

  // Check authentication requirement
  if (to.meta.requiresAuth) {
    // Fetch user profile
    try {
      await userStore.getProfile();
    } catch {
      // Redirect to log in if an error occurs during profile fetching
      return next({ name: 'Login', query: { from: to.fullPath } });
    }

    // If the user is authenticated
    if (userStore.profile && userStore.profile.id) {
      // Example forbidden logic (currently always false)
      const forbidden = false;
      if (!forbidden) {
        return next();
      }
      return next({ name: 'landingURL' });
    }

    // Redirect to log in if not authenticated
    return next({ name: 'Login', query: { from: to.fullPath } });
  }

  // If no authentication is required, proceed
  next();
});

export default router;
