<template>
  <div class="flex flex-col gap-5">
    <div
      class="w-full standard-elevation-0-dark rounded-[6px] p-5 flex flex-col gap-5"
    >
      <h5 class="text-title-color1">
        Anschlussbereiche der relevanten Anschlussleistung* für
        PV-Potenzialflächen
      </h5>
      <div
        class="flex flex-col gap-5 p-5 standard-elevation-0-dark rounded-[4px]"
      >
        <div class="flex flex-col gap-2.5">
          <div class="flex justify-between items-center gap-5">
            <div class="subtitle-2 text-neutral">
              Mittelspannung – Netzverknüpfungspunkt (mit freier Netzkapazität)
            </div>
            <ToolTip>
              <template #text>Tooltip</template>
            </ToolTip>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <InputEl
              :disabled="true"
              label="Anschlussleistung von"
              input-type="integer"
              placeholder="0"
              class="w-full"
              suffix="ha"
            />
            <InputEl
              v-model="maxLoadGridConnection"
              label="bis"
              input-type="integer"
              placeholder="Bitte eingeben"
              class="w-full"
              suffix="ha"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2.5">
          <div class="flex justify-between items-center gap-5">
            <div class="subtitle-2 text-neutral">
              Mittelspannung - Umspannwerk Netzbetreiber (mit freier
              Netzkapazität)
            </div>
            <ToolTip>
              <template #text>Tooltip</template>
            </ToolTip>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <InputEl
              v-model="maxLoadGridConnection"
              :disabled="true"
              label="Anschlussleistung von"
              input-type="integer"
              placeholder="Bitte eingeben"
              class="w-full"
            />
            <InputEl
              v-model="maxLoadSubstation"
              label="bis"
              input-type="integer"
              placeholder="Bitte eingeben"
              class="w-full"
            />
          </div>
        </div>

        <div class="flex flex-col gap-2.5">
          <div class="flex justify-between items-center gap-5">
            <div class="subtitle-2 text-neutral">Hochspannung</div>
            <ToolTip>
              <template #text>Tooltip</template>
            </ToolTip>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <InputEl
              v-model="maxLoadSubstation"
              :disabled="true"
              label="Anschlussleistung von"
              input-type="integer"
              placeholder="Bitte eingeben"
              class="w-full"
            />
            <InputEl
              :disabled="true"
              label="bis"
              input-type="integer"
              placeholder="∞"
              class="w-full"
            />
          </div>
        </div>
      </div>
      <div class="caption-1 text-neutral">
        *PV-Leistung abzüglich Speicherleistung
      </div>
    </div>

    <div
      class="w-full standard-elevation-0-dark rounded-[6px] p-5 flex flex-col gap-5"
    >
      <h5 class="text-title-color1">Dimensionierung Energiespeicher</h5>

      <div class="flex items-center gap-3">
        <InputEl
          v-model="batteryProportion"
          input-type="float"
          placeholder="Bitte eingeben"
          class="w-20"
          suffix="%"
        />
        <span class="body-2 pt-0.5"
          >Auslegungsleistung (anteilig an installierter PV-Leistung</span
        >
        <ToolTip>
          <template #text>Tooltip</template>
        </ToolTip>
      </div>
    </div>

    <div
      class="w-full standard-elevation-0-dark rounded-[6px] p-5 flex flex-col gap-5"
    >
      <h5 class="text-title-color1">Route Netzanschluss</h5>
      <VRadioGroup v-model="gridConnectionChoice" class="max-w-fit">
        <VRadio
          v-for="(choice, index) in gridConnectionChoices"
          :key="choice.value"
          :value="choice.value"
        >
          <template #label>
            <div class="body-2 pt-0.5 my-2">
              {{ choice.display_name }}
            </div>
            <ToolTip v-if="index === 1" class="ml-2">
              <template #text> Test</template>
            </ToolTip>
          </template>
        </VRadio>
      </VRadioGroup>
    </div>
  </div>
</template>

<script setup>
import InputEl from '@/components/input/InputEl.vue';
import { ref, watch } from 'vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';

const props = defineProps({
  gridConnectionChoices: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(['update']);

defineExpose({ populateInputs });

// TODO: Validation dass grid nicht größer als substation
const maxLoadGridConnection = ref(15);
const maxLoadSubstation = ref(25);
const batteryProportion = ref(40);
const gridConnectionChoice = ref(props.gridConnectionChoices[0].value);

watch(
  [
    maxLoadGridConnection,
    maxLoadSubstation,
    batteryProportion,
    gridConnectionChoice,
  ],
  () => {
    emit('update', {
      max_load_for_grid_connection_point_mw: maxLoadGridConnection.value,
      max_load_for_substation_mw: maxLoadSubstation.value,
      battery_capacity_proportion: batteryProportion.value,
      routing_option: gridConnectionChoice.value,
    });
  },
);

function populateInputs(potentialAnalysis) {
  maxLoadGridConnection.value =
    potentialAnalysis.max_load_for_grid_connection_point_mw;
  maxLoadSubstation.value = potentialAnalysis.max_load_for_substation_mw;
  batteryProportion.value = potentialAnalysis.battery_capacity_proportion;
  gridConnectionChoice.value = potentialAnalysis.routing_option;
}
</script>
