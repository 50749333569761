import time from './time';
import theme from './theme';
import locales from './locales';
import constraints from './map.constraints';

export default {
  // product display information
  product: {
    name: 'Infra|Analytics',
    version: '1.0.0',
  },

  // time configs
  time,

  // theme configs
  theme,

  // locales configs
  locales,

  // constraints configs for map view
  constraints,
};
