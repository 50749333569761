<template>
  <TopHeaderBar />
  <div class="flex flex-col gap-5">
    <div class="flex items-center gap-4">
      <IconWrapper
        v-if="IconAnalysis"
        :size="64"
        :icon="IconAnalysis"
        fill="text-core-color2"
      />
      <h1 class="text-title-neutral">Potentialanalyse</h1>
    </div>
    <div class="flex flex-col gap-5">
      <VuetifyDataTable
        :headers="tableHeadersPotentials"
        :item-data="[]"
        :show-expand="false"
      >
      </VuetifyDataTable>
      <ButtonEl
        v-if="!showForm"
        icon="add"
        icon-fill="text-inverted-color1"
        text="Neu"
        class="w-fit"
        @click="showModal = true"
      />
      <PotentialAnalysisPageCreateDialog
        :show-modal
        :potential-analysis
        @update:show-form="showForm = $event"
        @update:show-modal="showModal = $event"
        @update:potential-analysis="Object.assign(potentialAnalysis, $event)"
      />
    </div>
    <div
      v-if="showForm"
      class="bg-white rounded-[8px] standard-elevation-0-dark p-5 flex flex-col gap-5"
    >
      <!-- Todo: Get label, rules and so on from backend -->
      <div class="flex justify-between items-center gap-5">
        <h3 class="text-title-neutral">Konfiguration Potenzialanalyse</h3>
        <div class="flex items-center gap-5">
          <ButtonEl
            text="Stand zwischenspeichern"
            color="color2"
            variant="secondary"
            icon="save"
          />
          <ButtonEl
            text="Berechnung starten"
            icon="table_view"
            @click="create()"
          />
          <IconWrapper
            icon="close"
            class="cursor-pointer ml-5"
            fill="text-core-color1"
            @click="showForm = false"
          />
        </div>
      </div>
      <!-- Min-height to not cut off FilterDropdown, when all items filtered away -->
      <FormTabs
        :tabs="tabs"
        :eager="true"
        custom-window-classes="min-h-[300px] "
      >
        <template #slot0>
          <PotentialAnalysisGeoSelection
            ref="geoSelection"
            @update="Object.assign(potentialAnalysis, $event)"
          />
        </template>
        <template #slot1>
          <PotentialAnalysisPvSetting
            ref="pvSetting"
            @update="Object.assign(potentialAnalysis, $event)"
          />
        </template>
        <template #slot2>
          <PotentialAnalysisAreaSetting
            ref="areaSetting"
            @update="Object.assign(potentialAnalysis, $event)"
          />
        </template>
        <template #slot3>
          <PotentialAnalysisGridConnection
            ref="gridConnection"
            :grid-connection-choices="potentialMetaData.routing_option.choices"
            @update="Object.assign(potentialAnalysis, $event)"
          />
        </template>
        <template #slot4>
          <PotentialAnalysisRestrictions
            ref="analysisRestrictions"
            :potential-analysis="potentialAnalysis"
            @update:potential-analysis-distance="
              potentialAnalysis.distance = $event
            "
          />
        </template>
        <template #slot5>
          <PotentialAnalysisAreaQualification
            ref="AreaQualification"
            :agricultural-plots-meta="
              potentialMetaData.agricultural_plots_list.child.choices
            "
            :disadvantage-area-meta="
              potentialMetaData.disadvantage_area_list.child.choices
            "
            @update="Object.assign(potentialAnalysis, $event)"
          />
        </template>
      </FormTabs>
    </div>
  </div>
</template>

<script setup>
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconAnalysis from '@/assets/icons/custom/misc/IconAnalysis.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import {
  convertMetaDataToFormRules,
  restGetOrFetchMetaData,
  restPost,
} from '@/utils/rest-utils';
import { ref } from 'vue';
import { PotentialAnalysisDto } from '@/apps/usecase-2/potential-analysis/potential-analysis-dto';
import PotentialAnalysisPvSetting from '@/apps/usecase-2/potential-analysis/PotentialAnalysisPvSetting.vue';
import PotentialAnalysisRestrictions from '@/apps/usecase-2/potential-analysis/PotentialAnalysisRestrictions.vue';
import PotentialAnalysisAreaQualification from '@/apps/usecase-2/potential-analysis/PotentialAnalysisAreaQualification.vue';
import PotentialAnalysisAreaSetting from '@/apps/usecase-2/potential-analysis/PotentialAnalysisAreaSetting.vue';
import PotentialAnalysisGridConnection from '@/apps/usecase-2/potential-analysis/PotentialAnalysisGridConnection.vue';
import {
  tableHeadersPotentials,
  tabs,
} from '@/apps/usecase-2/potential-analysis/potential-analysis-data';
import PotentialAnalysisGeoSelection from '@/apps/usecase-2/potential-analysis/PotentialAnalysisGeoSelection.vue';
import { useBaseStore } from '@/apps/basemodule/base-store';
import PotentialAnalysisPageCreateDialog from '@/apps/usecase-2/potential-analysis/components/PotentialAnalysisPageCreateDialog.vue';

const baseStore = useBaseStore();

const showForm = ref(false);
const showModal = ref(false);
const potentialAnalysis = ref(new PotentialAnalysisDto());

// ------ children for populating input data
const geoSelection = ref(null);
const pvSetting = ref(null);
const areaSetting = ref(null);
const gridConnection = ref(null);
const analysisRestrictions = ref(null);
const AreaQualification = ref(null);

const potentialMetaData = await restGetOrFetchMetaData(PotentialAnalysisDto);
const potentialFormRules = convertMetaDataToFormRules(potentialMetaData);

function create() {
  restPost(potentialAnalysis.value, [
    'title',
    'description',
    'municipalities',
    'min_area_ha',
    'max_count_parcels',
    'orientation_value_greater_equal_than',
    'orientation_value_smaller_equal_than',
    'slope_degree_value_greater_equal_than',
    'slope_degree_value_smaller_equal_than',
    'max_load_for_grid_connection_point_mw',
    'max_load_for_substation_mw',
    'battery_capacity_proportion',
    'routing_option',
    'disadvantage_area_list',
    'agricultural_plots_list',
    'specific_plant_size_mw_ha',
    'dc_ac_ratio',
    'inverter_efficiency',
    'performance_ratio',
    'mounting_slope_degree',
  ]);
  showForm.value = false;
}

function fillForm(id) {
  // TODO: Fetch instance and populate inputs if edit or watch mode active

  const componentsToPopulate = [
    geoSelection,
    pvSetting,
    areaSetting,
    gridConnection,
    analysisRestrictions,
    AreaQualification,
  ];

  componentsToPopulate.forEach((component) => {
    if (
      component.value &&
      typeof component.value.populateInputs === 'function'
    ) {
      component.value.populateInputs(potentialAnalysis);
    }
  });
}
</script>
