<template>
  <div class="flex gap-2">
    <div class="flex gap-1">
      <layerToggle
        v-if="toggleOption.includeCustomToggle"
        :on-layer="toggleOption.includeCustomToggle.onLayer"
        :layer-state="layerState"
        :icon="toggleOption.includeCustomToggle.icon"
        :grand-parent-id
        :parent-id
      />
      <layerToggle
        v-if="toggleOption.includeBuildingToggle"
        :on-layer="LAYER_KEY__BUILDINGS"
        :layer-state="layerState"
        :icon="'maps_home_work'"
        :grand-parent-id
        :parent-id
      />
      <layerToggle
        v-if="toggleOption.includeAggregationToggle"
        :on-layer="aggregationLayerActive"
        :layer-state="layerState"
        :icon="'layers'"
        :grand-parent-id
        :parent-id
        @update-style="changeAggregationStyle($event)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMapStore } from '@/apps/usecase-1/map/map-store';
import { LAYER_KEY__BUILDINGS } from '../../layer-config/buildings';
import LayerToggle from './LayerToggle.vue';

const mapStore = useMapStore();

const props = defineProps({
  toggleOption: {
    type: Object,
    required: true,
  },
  layerState: {
    type: String,
    required: true,
  },
  parentId: {
    type: String || null,
    default: null,
  },
  grandParentId: {
    type: String || null,
    default: null,
  },
});

const aggregationLayerActive = computed(() => {
  const aggregationLevelActive = mapStore.aggregationLevel;
  const onAggregationLayers =
    props.toggleOption?.includeAggregationToggle?.onLayers;
  if (
    onAggregationLayers &&
    !onAggregationLayers.includes(aggregationLevelActive)
  ) {
    return 'null';
  }
  return mapStore.aggregationLevel;
});

function changeAggregationStyle(layerStyle) {
  mapStore.setAggregationStyle(layerStyle);
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles';
</style>
