<template>
  <div class="flex justify-between items-center">
    <ButtonEl
      icon="chevron_left"
      text="zurück"
      color="color2"
      class="back-button-custom"
      @click="goBack(goBackToCustom)"
    />

    <slot />
  </div>
</template>
<script setup>
import ButtonEl from '@/components/button/ButtonEl.vue';
import { useGoBack } from '@/composables/useGoBack';

const props = defineProps({
  goBackToCustom: {
    type: String,
    default: null,
  },
});

const goBack = useGoBack();
</script>

<style scoped lang="scss">
:deep(.back-button-custom > span) {
  padding-bottom: 3px !important;
}
</style>
