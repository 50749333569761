const normalizeString = (input) => {
  // Ensure input is a string, or default to an empty string
  if (typeof input !== 'string') return '';

  return input.toLowerCase().trim().replace(/\s+/g, '');
};

const formatValueByType = (value, type) => {
  // Ensure value is treated as a string to safely use .replace
  const stringValue = String(value);
  const cleanedValue = stringValue.replace(/[^0-9.-]/g, '');

  switch (type) {
    case 'integer':
      return parseInt(cleanedValue, 10);
    default:
      return parseFloat(stringValue);
  }
};

// Sometimes there is a function returned. This allows the usage with fewer parameters.
export const rules = {
  required: (value) =>
    (value !== null && value !== undefined && value !== '') || 'Pflichtfeld',
  email: (value) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
    'Bitte geben Sie eine gültige E-Mail-Adresse ein',
  length: (v) =>
    (v && v.length >= 8) || 'Passwort sollte aus min. 8 Zeichen bestehen',
  minLength: (minLength) => {
    return (v) =>
      v.length >= minLength || `Die minimale Länge beträgt ${minLength}`;
  },
  maxLength: (maxLength) => {
    return (v) =>
      v.length <= maxLength || `Die maximale Länge beträgt ${maxLength}`;
  },
  uppercase: (v) =>
    /(?=.*[A-Z])/.test(v) || 'Passwort sollte Großbuchstaben enthalten',
  number: (v) => /(?=.*\d)/.test(v) || 'Passwort sollte Zahl enthalten',
  specialChar: (v) =>
    /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(v) ||
    'Passwort sollte Sonderzeichen enthalten',
  match: (passwordConfirm) => {
    return (value) =>
      value === passwordConfirm || 'Passwörter stimmen nicht überein';
  },
  unique: (uniqueList) => {
    return (input) => {
      if (!input || !uniqueList || uniqueList.length === 0) return true;
      const inputToCheck = normalizeString(input);
      const uniqueToLowerCase = uniqueList.map((item) => normalizeString(item));
      return (
        !uniqueToLowerCase.includes(inputToCheck) || 'Name bereits vergeben'
      );
    };
  },
  fileExtension: (extension) => {
    return (file) => {
      if (file === null) {
        return true;
      } else {
        const extensionOfFile = file.name.split('.').pop();
        return (
          extensionOfFile === extension ||
          `Datei-Format muss .${extension} sein`
        );
      }
    };
  },
  min: (minValue, type = 'integer') => {
    return (value) => {
      const multiplier = type === 'percent' ? 100 : 1;
      const cleanedValue = formatValueByType(value, type);

      if (isNaN(cleanedValue) || cleanedValue < minValue) {
        return `Wert muss größer oder gleich ${(minValue * multiplier).toLocaleString('de-DE')} sein`;
      }
      return true;
    };
  },
  max: (maxValue, type = 'integer') => {
    return (value) => {
      const multiplier = type === 'percent' ? 100 : 1;
      const cleanedValue = formatValueByType(value, type);

      if (isNaN(cleanedValue) || cleanedValue > maxValue) {
        return `Wert muss kleiner oder gleich ${(maxValue * multiplier).toLocaleString('de-DE')} sein`;
      }
      return true;
    };
  },
  range: (minValue, maxValue, type = 'integer') => {
    return (value) => {
      // If either minValue or maxValue are undefined, always return true
      if (minValue === undefined || maxValue === undefined) {
        return true;
      }

      const multiplier = type === 'percent' ? 100 : 1;
      const cleanedValue = formatValueByType(value, type);
      if (
        isNaN(cleanedValue) ||
        cleanedValue < minValue ||
        cleanedValue > maxValue
      ) {
        return `Wert muss zwischen ${(minValue * multiplier).toLocaleString('de-DE')} und ${(
          maxValue * multiplier
        ).toLocaleString('de-DE')} sein`;
      }
      return true;
    };
  },
};
