import { defineStore } from 'pinia';
import { axios } from '@/utils/axiosHelper';
import { sum } from 'lodash';
import router from '@/router';
import store from '@/store/index';

function routeBackIfNotFound() {
  router
    .push({
      name: 'UseCase1ProjectPage',
    })
    .then((r) => {
      store.commit('layout/SHOWTOAST', {
        color: 'error',
        message: 'Szenario/Projekt nicht abgeschlossen!',
      });
    });
}

export const useProjectResult = defineStore('projectResult', {
  state: () => ({
    scenarios: [],
    activeProject: null,
    activeScenario: null,
    buildingData: {},
    potentialGenerationData: {},
    demandData: {},
    infrastructureData: {},
    heatDemandByTechnologyData: {},
    municipalityComparisonData: {},
    projectScenarioComparison: null,
    assumptionsGeneral: null,
    scalingElectrificationMobility: 1,
    scalingElectrificationHeatSingle: 1,
    scalingElectrificationHeatNetwork: 1,
    // values to use in grid planning
    appliedScalingElectrificationMobility: null,
    appliedScalingElectrificationHeatSingle: null,
    appliedScalingElectrificationHeatNetwork: null,
    totalIncreasePvRoofMw: 0,
    totalIncreasePvFreeMw: 0,
    totalIncreaseWindMw: 0,
    // values to use in grid planning
    appliedTotalIncreasePvRoofMw: null,
    appliedTotalIncreasePvFreeMw: null,
    appliedTotalIncreaseWindMw: null,
    // id of applied generation scaling
    appliedGenerationScalingId: null,
    appliedDemandScalingId: null,
  }),
  actions: {
    async getScenariosByProject(projectId) {
      try {
        const scenarioData = await axios({
          method: 'GET',
          url: `/api/usecase-1/scenario/`,
          params: { project_id: projectId },
        });
        this.scenarios = scenarioData.data.results;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    /**
     * get general assumptions
     */
    async fetchAssumptionsGeneral() {
      try {
        const assumptions = await axios({
          method: 'GET',
          url: `/api/usecase-1/assumptions-general/`,
        });
        this.assumptionsGeneral = assumptions.data.results[0];
        return this.assumptionsGeneral;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchAssumptionsScenario(assumptionId) {
      try {
        const assumptions = await axios({
          method: 'GET',
          url: `/api/usecase-1/assumptions-scenario/${assumptionId}/`,
        });
        this.assumptionsScenario = assumptions.data;
        return this.assumptionsScenario;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    /**
     * get project data without relations
     * @param  {number} projectId id of project to fetch
     */
    async getProject(projectId) {
      try {
        const projectResp = await axios({
          method: 'GET',
          url: `/api/usecase-1/project/${projectId}/`,
        });
        this.activeProject = projectResp.data.result;
        return this.activeProject;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    /**
     * get project data and an array of related municipalities
     * @param  {number} projectId id of project to fetch
     */
    async getProjectDetail(projectId) {
      try {
        const fetchProject = axios({
          method: 'GET',
          url: `/api/usecase-1/project/${projectId}/`,
        });
        const fetchMuniciplaities = axios({
          method: 'GET',
          url: `/api/usecase-1/municipalities-by-project/${projectId}/`,
        });
        const resp = await Promise.all([fetchProject, fetchMuniciplaities]);
        this.activeProject = {
          ...resp[0].data,
          municipalities: resp[1].data.result.municipalities.map((m) =>
            String(m.id),
          ),
          municipalitiesData: resp[1].data.result.municipalities,
        };
        return this.activeProject;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchBuildingData(projectId) {
      if (!this.buildingData[projectId]) {
        try {
          const buildingData = await axios({
            method: 'GET',
            url: `/api/usecase-1/project-evaluation/${projectId}/`,
          });
          this.buildingData[projectId] = buildingData.data;
        } catch (error) {
          return Promise.reject(error);
        }
      }
      return this.buildingData[projectId];
    },
    /**
     * get demand data for scenario grouped by year and sector
     * [{year: x, sector: x, values}, {year: x, sector: y, values}, ...]
     * @param  {number} scenarioId id of data to fetch
     * @param  {number} projectId id of data to fetch
     */
    async fetchDemandData(projectId, scenarioId) {
      if (this.demandData[scenarioId]) {
        return this.demandData[scenarioId];
      }
      try {
        const demandData = await axios({
          method: 'GET',
          url: `/api/usecase-1/scenario-evaluation/demand/${projectId}/${scenarioId}/`,
        });
        this.demandData[scenarioId] = demandData.data;
        return this.demandData[scenarioId];
      } catch (error) {
        if (error.request.status === 404) {
          routeBackIfNotFound();
        }
        return Promise.reject(error);
      }
    },
    async fetchInfrastructureData(projectId, scenarioId) {
      if (this.infrastructureData[scenarioId]) {
        return this.infrastructureData[scenarioId];
      }
      try {
        const data = await axios({
          method: 'GET',
          url: `/api/usecase-1/scenario-evaluation/infrastructure/${projectId}/${scenarioId}/`,
        });
        this.infrastructureData[scenarioId] = data.data;
        return this.infrastructureData[scenarioId];
      } catch (error) {
        if (error.request.status === 404) {
          routeBackIfNotFound();
        }
        return Promise.reject(error);
      }
    },
    async fetchPotentialGenerationData(projectId, scenarioId) {
      if (this.potentialGenerationData[scenarioId]) {
        return this.potentialGenerationData[scenarioId];
      }
      try {
        const potentialGenerationData = await axios({
          method: 'GET',
          url: `/api/usecase-1/scenario-evaluation/potential-generation/${projectId}/${scenarioId}/`,
        });
        this.potentialGenerationData[scenarioId] =
          potentialGenerationData.data.data;
        return this.potentialGenerationData[scenarioId];
      } catch (error) {
        if (error.request.status === 404) {
          routeBackIfNotFound();
        }
        return Promise.reject(error);
      }
    },
    async fetchMunicipalityComparison(projectId, scenarioId) {
      if (this.municipalityComparisonData[scenarioId]) {
        return this.municipalityComparisonData[scenarioId];
      }
      try {
        const municipalityComparisonData = await axios({
          method: 'GET',
          url: `/api/usecase-1/scenario-evaluation/project-municipality-comparison/${projectId}/${scenarioId}/`,
        });
        this.municipalityComparisonData[scenarioId] =
          municipalityComparisonData.data.data;
        return this.municipalityComparisonData[scenarioId];
      } catch (error) {
        if (error.request.status === 404) {
          routeBackIfNotFound();
        }
        return Promise.reject(error);
      }
    },
    async fetchProjectScenarioComparison(projectId) {
      try {
        if (this.projectScenarioComparison === null) {
          const resp = await axios({
            method: 'GET',
            url: `/api/usecase-1/project-evaluation/project-scenario-comparison/${projectId}/`,
          });
          this.projectScenarioComparison = resp.data;
        }
        return this.projectScenarioComparison;
      } catch (error) {
        if (error.request.status === 404) {
          routeBackIfNotFound();
        }
        return Promise.reject(error);
      }
    },
    async applyDefaultGenerationScaling(scenarioId) {
      if (
        this.appliedTotalIncreasePvRoofMw !== null &&
        this.appliedTotalIncreasePvFreeMw !== null &&
        this.appliedTotalIncreaseWindMw !== null
      ) {
        return;
      }

      const values = { pvRoof: 0, pvFree: 0, wind: 0 };

      let assumptionsGenerationGrowth = await axios({
        method: 'GET',
        url: '/api/usecase-1/assumptions-total-increase-generation/',
        params: { scenario_id: scenarioId },
      });

      assumptionsGenerationGrowth = assumptionsGenerationGrowth.data.results;

      const defaultIndex = assumptionsGenerationGrowth.findIndex(
        (e) => e.is_default,
      );
      // apply default if set else apply default logic
      if (defaultIndex >= 0) {
        const defaultInstance = assumptionsGenerationGrowth[defaultIndex];
        this.setTotalIncreasePvRoofMw(defaultInstance.pv_roof_mw, true);
        this.setTotalIncreasePvFreeMw(defaultInstance.pv_free_mw, true);
        this.setTotalIncreaseWindMw(defaultInstance.wind_mw, true);
      } else {
        let delta = this.scaledDemandTotalMwh - this.generationTotalMwh;
        if (delta <= 0) {
          this.setTotalIncreasePvRoofMwh(values.pvRoof, true);
          this.setTotalIncreasePvFreeMwh(values.pvFree, true);
          this.setTotalIncreaseWindMwh(values.wind, true);
          return;
        }

        values.pvRoof = Math.min(
          0.3 * this.potentialPvRoofMinusGenerationMwh,
          delta,
        );

        delta -= values.pvRoof;

        values.pvFree = Math.min(
          delta * (1 / 3),
          this.potentialPvFreeMinusGenerationMwh,
        );

        values.wind = Math.min(
          delta * (2 / 3),
          this.potentialWindMinusGenerationMwh,
        );

        this.setTotalIncreasePvRoofMwh(values.pvRoof, true);
        this.setTotalIncreasePvFreeMwh(values.pvFree, true);
        this.setTotalIncreaseWindMwh(values.wind, true);
      }
    },
    async applyDefaultDemandScaling(scenarioId) {
      if (
        this.appliedScalingElectrificationMobility !== null &&
        this.appliedScalingElectrificationHeatSingle !== null &&
        this.appliedScalingElectrificationHeatNetwork !== null
      ) {
        return;
      }
      const values = { mobility: 1, heatSingle: 1, heatNetwork: 1 };

      let demandScaling = await axios({
        method: 'GET',
        url: '/api/usecase-1/assumptions-scaling-factors-demand/',
        params: { scenario_id: scenarioId },
      });

      demandScaling = demandScaling.data.results;

      const defaultIndex = demandScaling.findIndex((e) => e.is_default);
      // apply default if set else apply default logic
      if (defaultIndex >= 0) {
        const defaultInstance = demandScaling[defaultIndex];
        values.mobility = defaultInstance.electrification_mobility;
        values.heatSingle = defaultInstance.electrification_heat_single;
        values.heatNetwork = defaultInstance.electrification_heat_network;
      }
      this.setScalingElectrificationMobility(values.mobility, true);
      this.setScalingElectrificationHeatSingle(values.heatSingle, true);
      this.setScalingElectrificationHeatNetwork(values.heatNetwork, true);
    },
    setAppliedGenerationScalingId(value) {
      this.appliedGenerationScalingId = value;
    },
    setAppliedDemandScalingId(value) {
      this.appliedDemandScalingId = value;
    },
    setScalingElectrificationMobility(value, applied = false) {
      this.scalingElectrificationMobility = value;
      if (applied) this.appliedScalingElectrificationMobility = value;
    },
    setScalingElectrificationHeatSingle(value, applied = false) {
      this.scalingElectrificationHeatSingle = value;
      if (applied) this.appliedScalingElectrificationHeatSingle = value;
    },
    setScalingElectrificationHeatNetwork(value, applied = false) {
      this.scalingElectrificationHeatNetwork = value;
      if (applied) this.appliedScalingElectrificationHeatNetwork = value;
    },
    setTotalIncreasePvRoofMw(value, applied = false) {
      this.totalIncreasePvRoofMw = value;
      if (applied) this.appliedTotalIncreasePvRoofMw = value;
    },
    setTotalIncreasePvFreeMw(value, applied = false) {
      this.totalIncreasePvFreeMw = value;
      if (applied) this.appliedTotalIncreasePvFreeMw = value;
    },
    setTotalIncreaseWindMw(value, applied = false) {
      this.totalIncreaseWindMw = value;
      if (applied) this.appliedTotalIncreaseWindMw = value;
    },
    setTotalIncreasePvRoofMwh(value, applied = false) {
      const valueMw =
        value / this.assumptionsScenario.pv_roof_full_load_hours_h_a;
      this.totalIncreasePvRoofMw = valueMw;
      if (applied) this.appliedTotalIncreasePvRoofMw = valueMw;
    },
    setTotalIncreasePvFreeMwh(value, applied = false) {
      const valueMw =
        value / this.assumptionsScenario.pv_free_full_load_hours_h_a;
      this.totalIncreasePvFreeMw = valueMw;
      if (applied) this.appliedTotalIncreasePvFreeMw = valueMw;
    },
    setTotalIncreaseWindMwh(value, applied = false) {
      const valueMw = value / this.assumptionsScenario.wind_full_load_hours_h_a;
      this.totalIncreaseWindMw = valueMw;
      if (applied) this.appliedTotalIncreaseWindMw = valueMw;
    },
    setActiveScenarioById(scenarioId) {
      this.activeScenario = this.scenarios.find(
        (e) => e.id === parseInt(scenarioId),
      );
    },
  },
  getters: {
    getHeatDemandByTechnology(state) {
      return state.heatDemandByTechnologyData[state.activeScenario.id];
    },
    getGenerationByTechnologyKwh(state) {
      const data = state.potentialGenerationData[state.activeScenario.id];
      return {
        wind: data.wind_generation_kwh_a,
        pvRoof: data.pv_roof_generation_kwh_a,
        pvFree: data.pv_free_generation_kwh_a,
        hydroPower: data.hydropower_generation_kwh_a,
        biomass: data.biomass_generation_kwh_a,
      };
    },
    getGenerationTotalKwh(state) {
      const data = state.potentialGenerationData[state.activeScenario.id];
      return (
        data.wind_generation_kwh_a +
        data.pv_roof_generation_kwh_a +
        data.pv_free_generation_kwh_a +
        data.hydropower_generation_kwh_a +
        data.biomass_generation_kwh_a
      );
    },
    getGenerationByTechnologyKw(state) {
      const data = state.potentialGenerationData[state.activeScenario.id];
      return {
        wind: data.wind_generation_kw,
        pvRoof: data.pv_roof_generation_kw,
        pvFree: data.pv_free_generation_kw,
        hydroPower: data.hydropower_generation_kw,
        biomass: data.biomass_generation_kw,
      };
    },
    getGenerationTotalKw(state) {
      const data = state.potentialGenerationData[state.activeScenario.id];
      return (
        data.wind_generation_kw +
        data.pv_roof_generation_kw +
        data.pv_free_generation_kw +
        data.hydropower_generation_kw +
        data.biomass_generation_kw
      );
    },
    getPotentialByTechnologyKwh(state) {
      const data = state.potentialGenerationData[state.activeScenario.id];
      return {
        wind: data.wind_potential_kwh_a,
        pvRoof: data.pv_roof_potential_kwh_a,
        pvFree: data.pv_free_potential_kwh_a,
      };
    },
    getPotentialTotalKwh(state) {
      const data = state.potentialGenerationData[state.activeScenario.id];
      return (
        data.wind_potential_kwh_a +
        data.pv_roof_potential_kwh_a +
        data.pv_free_potential_kwh_a
      );
    },
    getPotentialByTechnologyKw(state) {
      const data = state.potentialGenerationData[state.activeScenario.id];
      return {
        wind: data.wind_potential_kw,
        pvRoof: data.pv_roof_potential_kw,
        pvFree: data.pv_free_potential_kw,
      };
    },
    getPotentialTotalKw(state) {
      const data = state.potentialGenerationData[state.activeScenario.id];
      return (
        data.wind_potential_kw +
        data.pv_roof_potential_kw +
        data.pv_free_potential_kw
      );
    },
    /**
     * get demand data summed up for scenario and  field
     * as value and year as key. scenarioId{string} and fieldnames{Array} as args
     */
    demandFieldByYears: (state) => {
      return (scenarioId, field) => {
        const data = state.demandData[scenarioId].total_data;
        const result = {};
        try {
          data.forEach((item) => {
            result[item.year] = item[field];
          });
          return result;
        } catch (e) {
          console.error('data for scenario not found');
        }
      };
    },
    demandStatusQuoMw(state) {
      return (
        this.demandFieldByYears(
          state.activeScenario.id,
          'electricity_demand_base_kw',
        )[state.activeProject.base_year] / 1000
      );
    },
    demandStatusQuoMwh(state) {
      return (
        this.demandFieldByYears(
          state.activeScenario.id,
          'electricity_demand_base_kwh_a',
        )[state.activeProject.base_year] / 1000
      );
    },
    demandElectrificationMobilityMw(state) {
      return (
        this.demandFieldByYears(
          state.activeScenario.id,
          'electricity_demand_bev_kw',
        )[state.activeProject.target_year] / 1000
      );
    },
    demandElectrificationMobilityMwh(state) {
      return (
        this.demandFieldByYears(
          state.activeScenario.id,
          'electricity_demand_bev_kwh_a',
        )[state.activeProject.target_year] / 1000
      );
    },
    demandElectrificationHeatSingleMw(state) {
      return (
        this.demandFieldByYears(
          state.activeScenario.id,
          'electricity_demand_heat_pump_kw',
        )[state.activeProject.target_year] / 1000
      );
    },
    demandElectrificationHeatSingleMwh(state) {
      return (
        this.demandFieldByYears(
          state.activeScenario.id,
          'electricity_demand_heat_pump_kwh_a',
        )[state.activeProject.target_year] / 1000
      );
    },
    demandElectrificationHeatNetworkMw(state) {
      return (
        this.demandFieldByYears(
          state.activeScenario.id,
          'electricity_demand_heat_network_kw',
        )[state.activeProject.target_year] / 1000
      );
    },
    demandElectrificationHeatNetworkMwh(state) {
      return (
        this.demandFieldByYears(
          state.activeScenario.id,
          'electricity_demand_heat_network_kwh_a',
        )[state.activeProject.target_year] / 1000
      );
    },
    baseDemandDeltaMw(state) {
      const baseDemand = this.demandFieldByYears(
        state.activeScenario.id,
        'electricity_demand_base_kw',
      );
      return (
        (baseDemand[state.activeProject.base_year] -
          baseDemand[state.activeProject.target_year]) /
        1000
      );
    },
    baseDemandDeltaMwh(state) {
      const baseDemand = this.demandFieldByYears(
        state.activeScenario.id,
        'electricity_demand_base_kwh_a',
      );
      return (
        (baseDemand[state.activeProject.base_year] -
          baseDemand[state.activeProject.target_year]) /
        1000
      );
    },
    scaledDemandMobilityMwh(state) {
      return (
        state.scalingElectrificationMobility *
        this.demandElectrificationMobilityMwh
      );
    },
    scaledDemandHeatSingleMwh(state) {
      return (
        state.scalingElectrificationHeatSingle *
        this.demandElectrificationHeatSingleMwh
      );
    },
    scaledDemandHeatNetworkMwh(state) {
      return (
        state.scalingElectrificationHeatNetwork *
        this.demandElectrificationHeatNetworkMwh
      );
    },
    scaledDemandTotalMwh(state) {
      return sum([
        this.demandStatusQuoMwh,
        this.scaledDemandMobilityMwh,
        this.scaledDemandHeatSingleMwh,
        this.scaledDemandHeatNetworkMwh,
        -1 * this.baseDemandDeltaMwh,
      ]);
    },
    scaledDemandMobilityMw(state) {
      return (
        state.scalingElectrificationMobility *
        this.demandElectrificationMobilityMw
      );
    },
    scaledDemandHeatSingleMw(state) {
      return (
        state.scalingElectrificationHeatSingle *
        this.demandElectrificationHeatSingleMw
      );
    },
    scaledDemandHeatNetworkMw(state) {
      return (
        state.scalingElectrificationHeatNetwork *
        this.demandElectrificationHeatNetworkMw
      );
    },
    scaledDemandTotalMw(state) {
      return sum([
        this.demandStatusQuoMw,
        this.scaledDemandMobilityMw,
        this.scaledDemandHeatSingleMw,
        this.scaledDemandHeatNetworkMw,
        -1 * this.baseDemandDeltaMw,
      ]);
    },
    appliedScaledDemandMobilityMw(state) {
      return (
        state.appliedScalingElectrificationMobility *
        this.demandElectrificationMobilityMw
      );
    },
    appliedScaledDemandHeatSingleMw(state) {
      return (
        state.appliedScalingElectrificationHeatSingle *
        this.demandElectrificationHeatSingleMw
      );
    },
    appliedScaledDemandHeatNetworkMw(state) {
      return (
        state.appliedScalingElectrificationHeatNetwork *
        this.demandElectrificationHeatNetworkMw
      );
    },
    appliedScaledDemandTotalMw(state) {
      return sum([
        this.demandStatusQuoMw,
        this.appliedScaledDemandMobilityMw,
        this.appliedScaledDemandHeatSingleMw,
        this.appliedScaledDemandHeatNetworkMw,
        -1 * this.baseDemandDeltaMw,
      ]);
    },
    // Generation
    generationPvRoofMw(state) {
      return this.getGenerationByTechnologyKw.pvRoof / 1000;
    },
    generationPvFreeMw(state) {
      return this.getGenerationByTechnologyKw.pvFree / 1000;
    },
    generationWindMw(state) {
      return this.getGenerationByTechnologyKw.wind / 1000;
    },
    generationWaterMw(state) {
      return this.getGenerationByTechnologyKw.hydroPower / 1000;
    },
    generationBiomassMw(state) {
      return this.getGenerationByTechnologyKw.biomass / 1000;
    },
    generationTotalMw(state) {
      return sum([
        this.generationPvRoofMw,
        this.generationPvFreeMw,
        this.generationWindMw,
        this.generationWaterMw,
        this.generationBiomassMw,
      ]);
    },
    generationPvRoofMwh(state) {
      return this.getGenerationByTechnologyKwh.pvRoof / 1000;
    },
    generationPvFreeMwh(state) {
      return this.getGenerationByTechnologyKwh.pvFree / 1000;
    },
    generationWindMwh(state) {
      return this.getGenerationByTechnologyKwh.wind / 1000;
    },
    generationWaterMwh(state) {
      return this.getGenerationByTechnologyKwh.hydroPower / 1000;
    },
    generationBiomassMwh(state) {
      return this.getGenerationByTechnologyKwh.biomass / 1000;
    },
    generationTotalMwh(state) {
      return sum([
        this.generationPvRoofMwh,
        this.generationPvFreeMwh,
        this.generationWindMwh,
        this.generationWaterMwh,
        this.generationBiomassMwh,
      ]);
    },
    potentialPvRoofMw(state) {
      return this.getPotentialByTechnologyKw.pvRoof / 1000;
    },
    potentialPvFreeMw(state) {
      return this.getPotentialByTechnologyKw.pvFree / 1000;
    },
    potentialWindMw(state) {
      return this.getPotentialByTechnologyKw.wind / 1000;
    },
    potentialTotalMw(state) {
      return sum([
        this.potentialPvRoofMwh,
        this.potentialPvFreeMwh,
        this.potentialWindMwh,
      ]);
    },
    potentialPvRoofMwh(state) {
      return (
        this.potentialPvRoofMw *
        state.assumptionsScenario.pv_roof_full_load_hours_h_a
      );
    },
    potentialPvFreeMwh(state) {
      return (
        this.potentialPvFreeMw *
        state.assumptionsScenario.pv_free_full_load_hours_h_a
      );
    },
    potentialWindMwh(state) {
      return (
        this.potentialWindMw *
        state.assumptionsScenario.wind_full_load_hours_h_a
      );
    },
    potentialTotalMwh(state) {
      return sum([
        this.potentialPvRoofMwh,
        this.potentialPvFreeMwh,
        this.potentialWindMwh,
      ]);
    },
    generationTotalIncreaseMw(state) {
      return sum([
        this.generationTotalMw,
        state.totalIncreasePvRoofMw,
        state.totalIncreasePvFreeMw,
        state.totalIncreaseWindMw,
      ]);
    },
    totalIncreasePvRoofMwh(state) {
      return (
        state.totalIncreasePvRoofMw *
        state.assumptionsScenario.pv_roof_full_load_hours_h_a
      );
    },
    totalIncreasePvFreeMwh(state) {
      return (
        state.totalIncreasePvFreeMw *
        state.assumptionsScenario.pv_free_full_load_hours_h_a
      );
    },
    totalIncreaseWindMwh(state) {
      return (
        state.totalIncreaseWindMw *
        state.assumptionsScenario.wind_full_load_hours_h_a
      );
    },
    potentialPvRoofMinusGenerationMw(state) {
      return Math.max(state.potentialPvRoofMw - state.generationPvRoofMw, 0);
    },
    potentialPvFreeMinusGenerationMw(state) {
      return Math.max(state.potentialPvFreeMw - state.generationPvFreeMw, 0);
    },
    potentialWindMinusGenerationMw(state) {
      return Math.max(state.potentialWindMw - state.generationWindMw, 0);
    },
    potentialPvRoofMinusGenerationMwh(state) {
      return Math.max(state.potentialPvRoofMwh - state.generationPvRoofMwh, 0);
    },
    potentialPvFreeMinusGenerationMwh(state) {
      return Math.max(state.potentialPvFreeMwh - state.generationPvFreeMwh, 0);
    },
    potentialWindMinusGenerationMwh(state) {
      return Math.max(state.potentialWindMwh - state.generationWindMwh, 0);
    },
    generationTotalIncreaseMwh(state) {
      return sum([
        this.generationTotalMwh,
        state.totalIncreasePvRoofMw *
          state.assumptionsScenario.pv_roof_full_load_hours_h_a,
        state.totalIncreasePvFreeMw *
          state.assumptionsScenario.pv_free_full_load_hours_h_a,
        state.totalIncreaseWindMw *
          state.assumptionsScenario.wind_full_load_hours_h_a,
      ]);
    },
    potentialHydrogenMwh(state) {
      const potential =
        this.generationTotalIncreaseMwh - this.scaledDemandTotalMwh;
      if (potential > 0) return potential;
      return 0;
    },
    potentialHydrogenMw(state) {
      return (
        this.potentialHydrogenMwh /
        state.assumptionsScenario.water_full_load_hours_h_a
      );
    },
    investRenewableMioEur(state) {
      return (
        (state.totalIncreasePvRoofMw *
          state.assumptionsGeneral.invest_pv_roof_eur_kw +
          state.totalIncreasePvFreeMw *
            state.assumptionsGeneral.invest_pv_free_eur_kw +
          state.totalIncreaseWindMw *
            state.assumptionsGeneral.invest_wind_eur_kw) /
        1000
      );
    },
    municipalityLevyMioEur(state) {
      return (
        ((state.assumptionsGeneral.eeg_municipality_levy_ct_kwh / 100) *
          1000 *
          (this.totalIncreasePvFreeMwh + this.totalIncreaseWindMwh)) /
        1000000
      );
    },
    comparisonDataMunicipality: (state) => {
      return (municipality) => {
        const data =
          state.municipalityComparisonData[state.activeScenario.id][
            municipality
          ];
        if (!data) return {};
        const baseYear = state.activeProject.base_year;
        const targetYear = state.activeProject.target_year;
        const demandTarget = data.demand[targetYear];
        const demandBase = data.demand[baseYear];
        if (typeof demandTarget === 'undefined') {
          return {
            heatDemandTargetYearKw: 0,
            heatDemandTargetYearKwh: 0,
            heatDemandBaseYearKw: 0,
            heatDemandBaseYearKwh: 0,
            electricityDemandTargetYearKw: 0,
            electricityDemandTargetYearKwh: 0,
            electricityDemandBaseYearKw: 0,
            electricityDemandBaseYearKwh: 0,
            generationTotalKw: 0,
            potentialTotalKw: 0,
            windPotentialKw: 0,
            pvFreePotentialKw: 0,
            pvRoofPotentialKw: 0,
            biomassGenerationKw: 0,
            windGenerationKw: 0,
            hydropowerGenerationKw: 0,
            pvFreeGenerationKw: 0,
            pvRoofGenerationKw: 0,
            autarkie: 0,
            demandDriversTargetKwh: {
              mobility: 0,
              heatPump: 0,
              heatNetwork: 0,
              base: 0,
            },
          };
        }
        return {
          heatDemandTargetYearMw: demandTarget.sum_heat_demand_kw / 1000,
          heatDemandTargetYearMwh: demandTarget.sum_heat_demand_kwh_a / 1000,
          heatDemandBaseYearMw: demandBase.sum_heat_demand_kw / 1000,
          heatDemandBaseYearMwh: demandBase.sum_heat_demand_kwh_a / 1000,
          electricityDemandTargetYearMw:
            demandTarget.sum_electricity_demand_total_kw / 1000,
          electricityDemandTargetYearMwh:
            demandTarget.sum_electricity_demand_total_kwh_a / 1000,
          electricityDemandBaseYearMw:
            demandBase.sum_electricity_demand_total_kw / 1000,
          electricityDemandBaseYearMwh:
            demandBase.sum_electricity_demand_total_kwh_a / 1000,
          generationTotalMw: data.generation_total_kw / 1000,
          potentialTotalMw: data.potential_total_kw / 1000,
          windPotentialMw: data.wind_potential_kw / 1000,
          pvFreePotentialMw: data.pv_free_potential_kw / 1000,
          pvRoofPotentialMw: data.pv_roof_potential_kw / 1000,
          biomassGenerationMw: data.biomass_generation_kw / 1000,
          windGenerationMw: data.wind_generation_kw / 1000,
          hydropowerGenerationMw: data.hydropower_generation_kw / 1000,
          pvFreeGenerationMw: data.pv_free_generation_kw / 1000,
          pvRoofGenerationMw: data.pv_roof_generation_kw / 1000,
          autarkie: data.autarkie,
          demandDriversTargetMwh: {
            mobility: demandTarget.sum_electricity_demand_bev_kwh_a / 1000,
            heatPump:
              demandTarget.sum_electricity_demand_heat_pump_kwh_a / 1000,
            heatNetwork:
              demandTarget.sum_electricity_demand_heat_network_kwh_a / 1000,
            base: demandTarget.sum_electricity_demand_base_kwh_a / 1000,
          },
        };
      };
    },
    getComparisonMunicipalityRanges(state) {
      const data = state.municipalityComparisonData[state.activeScenario.id];
      return {
        generationTotalMw: {
          min: data.min_generation_total_kw / 1000,
          max: data.max_generation_total_kw / 1000,
        },
        potentialTotalMw: {
          min: data.min_potential_total_kw / 1000,
          max: data.max_potential_total_kw / 1000,
        },
        autarkie: {
          min: data.min_autarkie,
          max: data.max_autarkie,
        },
        heatDemandTargetYearMwh: {
          min: data.min_heat_demand_kwh_a / 1000,
          max: data.max_heat_demand_kwh_a / 1000,
        },
        electricityDemandTargetYearMwh: {
          min: data.min_electricity_demand_base_kwh_a / 1000,
          max: data.max_electricity_demand_base_kwh_a / 1000,
        },
      };
    },
  },
});
