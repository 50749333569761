<template>
  <div class="w-fit flex gap-1 items-center">
    <slot />
    <IconWrapper
      v-if="!$slots.default"
      :icon="tooltipIcon"
      :fill="tooltipIconFill"
      type="round"
      class="cursor-pointer"
    />
    <VTooltip
      v-if="tooltipText || $slots.text"
      activator="parent"
      :content-class="[
        `rounded-[2px] bg-white standard-elevation-0-dark
       min-w-fit`,
        customContentClass,
      ]"
    >
      <h6 v-if="!$slots.text" class="whitespace-break-spaces">
        {{ trimmedTooltipText }}
      </h6>
      <div
        v-if="!tooltipText"
        :style="{ 'max-width': `${slotMaxWidth}px` }"
        class="whitespace-normal"
      >
        <slot name="text" />
      </div>
    </VTooltip>
  </div>
</template>
<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { computed } from 'vue';

const props = defineProps({
  tooltipIcon: {
    type: [String, Object],
    default: 'info',
  },
  tooltipText: {
    type: [String, Boolean],
    default: null,
  },
  tooltipIconFill: {
    type: String,
    default: 'text-color1',
  },
  customContentClass: {
    type: String,
    default: 'p-2.5',
  },
  slotMaxWidth: {
    type: Number,
    default: 300,
  },
});

const trimmedTooltipText = computed(() => props.tooltipText?.trim());
</script>
