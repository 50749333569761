<template>
  <div
    style="width: 340px"
    class="dataPanelExpandablesWrapper border-t border-line-disabled-neutral"
  >
    <VExpansionPanels v-model="activeExpandableIndex" variant="accordion">
      <VExpansionPanel
        v-for="(expandable, index) in panelData.chips[activeChipIndex].items"
        :key="index"
        data-test="info-panel-expansion-panel"
        class="rounded-b-[8px]"
        elevation="0"
      >
        <VExpansionPanelTitle
          class="px-2.5 rounded-none text-title-neutral"
          :data-test="expandable.title"
          ><h6>
            {{ expandable.title }}
          </h6>
          <template #actions>
            <IconWrapper
              :size="16"
              :icon="activeExpandableIndex === index ? 'remove' : 'add'"
            />
          </template>
        </VExpansionPanelTitle>
        <VExpansionPanelText class="bg-active-area">
          <!-- text content start-->
          <template
            v-if="expandable.items.filter((e) => !e.panelItemType).length > 0"
          >
            <InfoPanelItem
              v-for="item in expandable.items"
              :key="item.title"
              :item="item"
              class="flex items-center text-neutral relative px-2.5 py-3"
            />
          </template>
          <!-- charts start-->
          <template
            v-if="expandable.items.filter((e) => e.panelItemType).length > 0"
          >
            <VCarousel class="carousel-custom" height="350px">
              <template #prev="{ props: prevProps }">
                <IconWrapper
                  v-if="
                    expandable.items.filter((e) => e.panelItemType).length > 1
                  "
                  icon="arrow_back"
                  class="cursor-pointer"
                  fill="text-color1"
                  type="sharp"
                  @click="prevProps.onClick"
                />
              </template>
              <template #next="{ props: nextProps }">
                <IconWrapper
                  v-if="
                    expandable.items.filter((e) => e.panelItemType).length > 1
                  "
                  icon="arrow_forward"
                  class="cursor-pointer"
                  fill="text-color1"
                  type="sharp"
                  @click="nextProps.onClick"
                />
              </template>
              <template #item="{ props: dotProps }">
                <div
                  v-if="
                    expandable.items.filter((e) => e.panelItemType).length > 1
                  "
                  :class="
                    dotProps.class.includes('v-btn--active')
                      ? 'bg-color1 pointer-events-none'
                      : 'bg-white border border-color1 hover:border-none hover:bg-button-primary-hover-color1'
                  "
                  class="w-4 h-4 rounded-full mb-[14px] cursor-pointer"
                  @click="dotProps.onClick"
                ></div>
              </template>
              <VCarouselItem
                v-for="item in expandable.items.filter((e) => e.panelItemType)"
                :key="item.title"
              >
                <BasicPieChart
                  class="w-full h-full px-1.5"
                  :title="item.title"
                  :chart-data="chartData(item)"
                  :custom-radius="['45%', '59%']"
                  :series-position="['50%', '45%']"
                  :title-inside="item.titleInside"
                  custom-title-classes="text-color1 -mb-5"
                  :custom-wrapper-padding="
                    expandable.items.filter((e) => e.panelItemType).length > 1
                      ? 'py-5'
                      : 'pt-5 pb-0'
                  "
                  legend-orient="horizontal"
                  :show-label="false"
                  legend-type="scroll"
                  :legend-border-radius="4"
                  legend-bg-color="white"
                  :legend-top="null"
                  :legend-right="null"
                  legend-bottom="24px"
                  :chart-unit="item.unit"
                  :title-custom-size="13"
                  :legend-data="item.legend || []"
                />
              </VCarouselItem>
            </VCarousel>
          </template>
          <!-- charts end-->
          <!-- text content end-->
        </VExpansionPanelText>
      </VExpansionPanel>
    </VExpansionPanels>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import InfoPanelItem from '@/apps/usecase-1/map/info-panel/InfoPanelItem.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';

const store = useStore();

const activeExpandableIndex = ref(0);
const emit = defineEmits(['close-info-panel']);
const props = defineProps({
  panelData: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  activeChipIndex: {
    type: Number,
  },
  feature: {
    type: Object,
  },
});

watch(
  () => props.activeChipIndex,
  () => {
    activeExpandableIndex.value = 0;
  },
);

watch(
  () => props.panelData,
  (newPanelData, oldPanelData) => {
    if (newPanelData.name !== oldPanelData.name) {
      activeExpandableIndex.value = 0;
    }
  },
);

function chartData(item) {
  const data = [];
  if (!item.value) return data;
  // if custom transfor function is defined, it is called in
  // panel-data-calculator and a value prop is set accordingly
  if (Object.hasOwn(item, 'customDataTransformFunction')) {
    return item.value;
  }
  item.value.forEach((e) => {
    const series = e[item.seriesFieldName];
    const value = e[item.valueFieldName];
    data.push({
      color: item.style[series].color,
      name: item.style[series].label,
      value: item.multiply ? value * item.multiply : value,
    });
  });
  return data;
}
</script>

<style lang="scss">
.dataPanelExpandablesWrapper {
  border-radius: 0 0 8px 8px;

  .v-input__details {
    display: none;
  }

  .box-shadow-custom {
    box-shadow: 0 1px 6px #00000029;
    padding-bottom: 1px;

    &:last-child {
      margin-bottom: -1px;
    }
  }

  .v-field__append-inner {
    padding-top: 0;
  }

  .v-expansion-panel-text__wrapper {
    @apply p-0;
  }

  .v-expansion-panel-title {
    @apply px-4 py-2;
    min-height: 50px !important;
  }

  .v-expansion-panel-title__overlay {
    opacity: 0;
  }

  .v-expansion-panel-title {
    min-height: 44px !important;
  }

  .v-expansion-panel-title--active {
    background-color: white;
  }

  .carousel-custom {
    .v-carousel__controls {
      background: transparent;
      max-height: 40px;
      height: fit-content;
      gap: 8px;
    }
  }
}
</style>
