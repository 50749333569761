<template>
  <div
    class="absolute flex bottom-5 left-5 h-[40px] z-10"
    :style="showSlider ? 'width: calc(100vw - 136px)' : ''"
  >
    <ButtonEl
      icon="update"
      :color="showSlider ? 'color1' : 'color2'"
      :class="[
        {
          'rounded-r-none': showSlider,
        },
        'standard-elevation-2 h-10 rounded-[8px]',
      ]"
      @click="toggleTimeMachineItems"
    />
    <div
      class="flex standard-elevation-2 rounded-r-[6px]"
      :class="
        showSlider
          ? 'extended-controls visible'
          : 'extended-controls-no-transition'
      "
    >
      <YearSlider class="rounded-l-none" :years="years" />
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import YearSlider from '@/components/year-slider/YearSlider.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { useMapStore } from '../../map-store';

const mapStore = useMapStore();

const showSlider = computed(() => mapStore.showSlider);

const props = defineProps({
  years: Array,
});

function toggleTimeMachineItems() {
  mapStore.showYearSlider(!showSlider.value);
}
</script>

<style scoped lang="scss">
.extended-controls {
  overflow: hidden;
  max-width: 0;
  transition: max-width 0.25s ease;
}

.extended-controls-no-transition {
  overflow: hidden;
  max-width: 0;
}

.visible {
  @apply w-full;
  max-width: calc(100vw - 175px);
}
</style>
