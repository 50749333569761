<template>
  <div class="flex flex-col gap-5">
    <div class="flex flex-col gap-2.5">
      <div class="button-1 text-title-neutral">Restriktionen einstellen</div>
      <div class="standard-elevation-0-dark p-5 rounded-[4px]">
        <InputEl
          :model-value="potentialAnalysis?.distance"
          label="Abstand"
          input-type="integer"
          placeholder="Bitte eingeben"
          class="w-[400px]"
          suffix="m"
          @update:model-value="emit('update:potentialAnalysisDistance', $event)"
        />
      </div>
    </div>

    <div class="flex gap-5 justify-between items-end min-h-[54px] pl-3">
      <ButtonEl
        text="Eingabe für folgende Subkategorien anwenden"
        icon="assignment_returned"
        color="color2"
        @click="updateDistance"
      />
      <div class="flex gap-2.5 items-end">
        <div
          v-show="showFilters"
          class="items-end gap-2.5"
          :class="{ flex: showFilters }"
        >
          <DropDownMultiple
            v-model="categoryFilter"
            label="Filter nach Kategorie"
            item-type-all="Kategorien"
            all-selected-label="Alle"
            class="w-[312px]"
            :items="restrictionsItemsForFiltering"
            data-key="name"
            value-key="category"
            placeholder="Bitte auswählen"
            :init-select-all="true"
          />
          <InputSearch
            :search-keys="['sub_category']"
            :items="
              dummyRestrictionsTableData.map((item) => ({
                ...item,
                sub_category: subCategoriesNamingMap[item.sub_category],
              }))
            "
            @update:search="
              textFilteredData = $event.map((item) => ({
                ...item,
                sub_category: reverseSubCategoriesNamingMap[item.sub_category],
              }))
            "
          />
        </div>
        <ButtonEl
          icon="filter_alt"
          color="color2"
          icon-type="round"
          class="w-10 h-10"
          @click="showFilters = !showFilters"
        />
      </div>
    </div>
    <VuetifyDataTable
      :headers="tableHeadersRestrictions"
      :item-data="filteredRestrictions"
      :show-expand="false"
      :header-small="true"
      :show-select="true"
      :select-all-checkboxes="true"
      @selected-rows="selectedCategories = $event"
    >
      <template #category="{ item }">
        {{ categoriesNamingMap[item.category] }}
      </template>
      <template #sub_category="{ item }">
        {{ subCategoriesNamingMap[item.sub_category] }}
      </template>
    </VuetifyDataTable>
  </div>
</template>

<script setup>
import ButtonEl from '@/components/button/ButtonEl.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import InputEl from '@/components/input/InputEl.vue';
import { computed, ref } from 'vue';
import DropDownMultiple from '@/components/dropDownMultiple/DropDownMultiple.vue';
import InputSearch from '@/components/inputSearch/InputSearch.vue';

// Todo: Adjust keys further
const tableHeadersRestrictions = [
  {
    title: 'Kategorie',
    key: 'category',
    width: '150px',
  },
  {
    title: 'Subkategorie',
    key: 'sub_category',
    width: '125px',
  },
  {
    title: 'Abstand',
    key: 'distance',
    width: '125px',
  },
];

const dummyRestrictionsTableData = ref([
  {
    id: 63,
    category: 'autobahn_and_streets',
    sub_category: 'federal_highway',
    distance: 200,
  },
  {
    id: 62,
    category: 'infrastructure',
    sub_category: 'railway',
    distance: 200,
  },
  {
    id: 54,
    category: 'nature_protection',
    sub_category: 'nationalparks',
    distance: 200,
  },
  {
    id: 23,
    category: 'settlements',
    sub_category: 'residential_areas',
    distance: 200,
  },
  {
    id: 19,
    category: 'autobahn_and_streets',
    sub_category: 'federal_waterway',
    distance: 200,
  },
  {
    id: 15,
    category: 'autobahn_and_streets',
    sub_category: 'county_road',
    distance: 200,
  },
]);

const categoriesNamingMap = {
  autobahn_and_streets: 'Autobahnen und Straßen',
  settlements: 'Siedlungen',
  nature_protection: 'Natur- und Landschaftsschutz',
  infrastructure: 'Infrastruktur',
};

const subCategoriesNamingMap = {
  federal_highway: 'Bundesautobahn',
  federal_waterway: 'Bundeswasserstraßen',
  residential_areas: 'Allgemeine und reine Wohngebiete',
  county_road: 'Kreisstraßen',
  nationalparks: 'Nationalparks',
  railway: 'Schienennetz',
};

const reverseSubCategoriesNamingMap = Object.fromEntries(
  Object.entries(subCategoriesNamingMap).map(([key, value]) => [value, key]),
);

const props = defineProps({
  potentialAnalysis: Object,
});

const emit = defineEmits(['update:potentialAnalysisDistance']);

const showFilters = ref(false);
const categoryFilter = ref([]);
const selectedCategories = ref(
  dummyRestrictionsTableData.value.map((item) => item.id),
);

const textFilteredData = ref(dummyRestrictionsTableData.value);

const filteredRestrictions = computed(() => {
  const filterObject = toObject(categoryFilter.value, 'category');
  return textFilteredData.value.filter((item) => {
    return filterObject[item.category];
  });
});

function toObject(arr, key) {
  return arr.reduce((result, item) => {
    return { ...result, [item[key]]: true };
  }, {});
}

const restrictionsItemsForFiltering = computed(() => {
  let uniqueById = {};

  return dummyRestrictionsTableData.value.reduce((result, item) => {
    if (!uniqueById[item.category]) {
      uniqueById[item.category] = {
        category: item.category,
        name: categoriesNamingMap[item.category],
      };
      return [...result, uniqueById[item.category]];
    }
    return result;
  }, []);
});

function updateDistance() {
  const idsObject = selectedCategories.value.reduce((result, id) => {
    result[id] = true;
    return result;
  }, {});

  dummyRestrictionsTableData.value = dummyRestrictionsTableData.value.map(
    (item) => {
      if (idsObject[item.id]) {
        item.distance = props.potentialAnalysis.distance;
      }
      return item;
    },
  );
}
</script>
