<template>
  <div>
    <h5 class="mb-5 mt-2 text-title-neutral">
      Ausweisung von Potenzialen nur bei folgenden Flächenkategorien:
    </h5>
    <div class="flex gap-5 mb-5">
      <div
        class="w-full standard-elevation-0-dark rounded-[6px] p-5 flex flex-col gap-2.5"
      >
        <div class="subtitle-2 text-title-neutral flex gap-2.5 items-center">
          Benachteiligte Flächen
          <ToolTip
            class="pb-0.5"
            tooltip-text="Benachteiligte Gebiete nach Maßgabe der FFÖ-VO bzw. des EEG auf Ebene der Gemarkungen. Sind Gemarkungen vollständig als benachteiligtes Gebiet erfasst, liegen alle Flurstücke der Gemarkung im benachteiligten Gebiet. In den Fällen, in denen nur Teilflächen als benachteiligtes Gebiet eingestuft sind, befinden sich nur Teile (Flurstücke) der Gemarkung im benachteiligten Gebiet."
          />
        </div>
        <div class="flex flex-col gap-2.5">
          <CheckboxEl
            v-for="choice in disadvantageAreaMeta"
            :key="choice.value"
            v-model="disadvantageArea"
            :label="choice.display_name"
            :value="choice.value"
          />
        </div>
      </div>
      <div
        class="w-full standard-elevation-0-dark rounded-[6px] p-5 flex flex-col gap-2.5"
      >
        <div class="subtitle-2 text-title-neutral flex gap-2.5 items-center">
          Landwirtschaftliche Parzellen
          <ToolTip
            class="pb-0.5"
            tooltip-text="Kategorisierung von landwirtschaftlichen Parzellen gemäß dem Geografischen Informationssystem Entwicklung in der Landwirtschaft (GISELa)."
          />
        </div>
        <div class="flex flex-col gap-2.5">
          <CheckboxEl
            v-for="choice in agriculturalPlotsMeta"
            :key="choice.value"
            v-model="agriculturalPlots"
            :label="choice.display_name"
            :value="choice.value"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { ref, watch } from 'vue';

const props = defineProps({
  agriculturalPlotsMeta: {
    type: Object,
    required: true,
  },
  disadvantageAreaMeta: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update']);

defineExpose({ populateInputs });

const agriculturalPlots = ref(props.agriculturalPlotsMeta.map((e) => e.value));
const disadvantageArea = ref(props.disadvantageAreaMeta.map((e) => e.value));

update();

watch([agriculturalPlots, disadvantageArea], () => {
  update();
});

function update() {
  console.log(agriculturalPlots.value);
  emit('update', {
    agricultural_plots_list: agriculturalPlots.value,
    disadvantage_area_list: disadvantageArea.value,
  });
}

function populateInputs(potentialAnalysis) {
  agriculturalPlots.value = potentialAnalysis.agricultural_plots_list;
  disadvantageArea.value = potentialAnalysis.disadvantage_area_list;
}
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  row-gap: 20px;
}
</style>
