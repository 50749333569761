<template>
  <div class="filter_group">
    <div
      v-if="menuItem.itemClass === 'toggle'"
      class="flex justify-between bg-active-area p-2.5 switch-list-item"
    >
      <span class="body-2 pt-0.5">{{ menuItem.title }}</span>
      <multiLayerToggle
        :toggle-option="menuItem.aggregation"
        :layer-state="menuItem.layerState"
        :grand-parent-id
        :parent-id
        class="-mr-0.5"
      />
    </div>

    <VList
      v-else
      :opened="[open]"
      class="bg-transparent font-medium p-0 border-infra-lines"
    >
      <VListGroup :value="menuItem.title" class="text-neutral">
        <template #activator="{ props }">
          <VListItem
            class="p-2.5"
            v-bind="props"
            :class="{
              'bg-active-area': itemLevel === 1,
            }"
            style="padding-inline: 10px !important"
            @click="emitOpened"
          >
            <VListItemTitle class="flex items-center">
              <span
                :class="{
                  'text-neutral': itemLevel === 0,
                  'text-color1': itemLevel === 1,
                }"
                class="subtitle-2 pt-0.5"
                >{{ menuItem.title }}</span
              >
              <IconWrapper
                v-if="hasActiveToggle"
                :size="16"
                icon="visibility"
                class="ml-auto mr-2 mt-[1px]"
              />
            </VListItemTitle>
            <template #append>
              <IconWrapper
                v-if="itemLevel === 0"
                fill="text-neutral"
                :icon="open === menuItem.title ? 'remove' : 'add'"
                :size="16"
              />
              <IconWrapper
                v-else-if="itemLevel === 1"
                fill="text-color1"
                :icon="
                  open !== menuItem.title
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'
                "
                :size="16"
              />
            </template>
          </VListItem>
        </template>
        <slot />
      </VListGroup>
    </VList>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useMapStore } from '@/apps/usecase-1/map/map-store';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import MultiLayerToggle from './input-forms/MultiLayerToggle.vue';
import { computed } from 'vue';

const mapStore = useMapStore();
const store = useStore();
const props = defineProps({
  menuItem: {
    type: Object,
    default: () => {},
  },
  open: {
    type: String,
    default: null,
  },
  itemLevel: {
    type: Number,
    default: 0,
  },
  parentId: {
    type: String || null,
    default: null,
  },
  grandParentId: {
    type: String || null,
    default: null,
  },
});
const emit = defineEmits(['emitActivator']);

const hasActiveToggle = computed(() => {
  if (!props.menuItem.id) return false;
  let hasId = false;
  for (const set of Object.values(mapStore.activeLayerToggleSections)) {
    if (set.has(props.menuItem.id)) {
      hasId = true;
      break;
    }
  }
  return hasId;
});

function emitOpened() {
  emit('emitActivator', {
    title: props.menuItem.title,
    level: props.itemLevel,
  });
}
</script>
