<template>
  <div class="circle-container">
    <svg :width="size" :height="size" viewBox="0 0 100 100">
      <!-- Full Circle -->
      <circle cx="50" cy="50" r="45" fill="#F3EFEC" stroke="none" />

      <!-- Active "Piece of Cake" (no stroke to center) -->
      <path :d="slicePath" fill="#C3BCB6" stroke="none" />

      <!-- Arc Border Only at the Edge -->
      <path :d="arcPath" fill="none" stroke="#009" stroke-width="1" />

      <!-- Dots on the border with white borders -->
      <circle
        :cx="startCoords.x"
        :cy="startCoords.y"
        r="3"
        fill="#009"
        stroke="white"
        stroke-width="1"
      />
      <circle
        :cx="endCoords.x"
        :cy="endCoords.y"
        r="3"
        fill="#009"
        stroke="white"
        stroke-width="1"
      />

      <!-- Center Blue Dot -->
      <circle cx="50" cy="50" r="1.5" fill="#009" />

      <!-- Orientation Labels (Always on Top) -->
      <text
        x="50"
        y="14"
        text-anchor="middle"
        font-size="5"
        fill="#333"
        class="orientation-label"
      >
        N
      </text>
      <text
        x="88"
        y="52"
        text-anchor="middle"
        font-size="5"
        fill="#333"
        class="orientation-label"
      >
        O
      </text>
      <text
        x="50"
        y="90"
        text-anchor="middle"
        font-size="5"
        fill="#333"
        class="orientation-label"
      >
        S
      </text>
      <text
        x="13"
        y="52"
        text-anchor="middle"
        font-size="5"
        fill="#333"
        class="orientation-label"
      >
        W
      </text>
    </svg>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [0, 90], // Starting and ending angles
  },
  size: {
    type: Number,
    default: 250, // Size of the SVG element
  },
});

const emit = defineEmits(['update:modelValue']);

// Using useVModel for the v-model management
const angles = useVModel(props, 'modelValue', emit);

// Helper functions
const toRadians = (angleInDegrees) => (angleInDegrees - 90) * (Math.PI / 180);

const polarToCartesian = (angle, radius) => {
  const x = 50 + radius * Math.cos(toRadians(angle));
  const y = 50 + radius * Math.sin(toRadians(angle));
  return { x, y };
};

// Validation function for angles
const isValidAngles = (angles) => {
  const [start, end] = angles;
  return start >= 0 && start <= 360 && end >= 0 && end <= 360 && start < end;
};

// Functions to calculate the paths, only if angles are valid
const calculatePath = (startAngle, endAngle) => {
  const radius = 45;
  const start = polarToCartesian(startAngle, radius);
  const end = polarToCartesian(endAngle, radius);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  return `
        M 50 50
        L ${start.x} ${start.y}
        A ${radius} ${radius} 0 ${largeArcFlag} 1 ${end.x} ${end.y}
        Z
      `;
};

const calculateArcPath = (startAngle, endAngle) => {
  const radius = 45;
  const start = polarToCartesian(startAngle, radius);
  const end = polarToCartesian(endAngle, radius);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  return `
        M ${start.x} ${start.y}
        A ${radius} ${radius} 0 ${largeArcFlag} 1 ${end.x} ${end.y}
      `;
};

// Computed values for coordinates, only if angles are valid
const startCoords = computed(() => {
  if (!isValidAngles(angles.value)) return { x: 50, y: 50 };
  const angle = angles.value[0];
  return polarToCartesian(angle, 45);
});

const endCoords = computed(() => {
  if (!isValidAngles(angles.value)) return { x: 50, y: 50 };
  const angle = angles.value[1];
  return polarToCartesian(angle, 45);
});

const slicePath = computed(() => {
  if (!isValidAngles(angles.value)) return '';
  const [start, end] = angles.value;
  return calculatePath(start, end);
});

const arcPath = computed(() => {
  if (!isValidAngles(angles.value)) return '';
  const [start, end] = angles.value;
  return calculateArcPath(start, end);
});
</script>

<style scoped lang="scss">
@import '@/assets/styles';

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.orientation-label {
  @apply text-neutral;
}
</style>
