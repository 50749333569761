<template>
  <div class="grid grid-cols-3 gap-5 w-full">
    <InputEl
      v-model="title"
      label="Name"
      placeholder="Bitte eingeben"
      class="w-full"
    />
    <InputEl
      v-model="description"
      label="Beschreibung"
      placeholder="Bitte eingeben"
      class="w-full"
    />
    <DropDown
      v-model="dataBasis"
      :items-data="[
        {
          label: 'Gebietsauswahl über administrative Grenzen',
          value: 'administrative',
        },
        {
          label: 'EnBW-Projektpipeline PV-Freifläche',
          value: 'projectPipeline',
        },
      ]"
      items-data-key="label"
      label="Datengrundlage"
      placeholder="Bitte eingeben"
      class="w-full"
      disabled
    />
  </div>
  <div
    class="flex gap-5 bg-default rounded-[8px] p-5 standard-elevation-0-dark w-full h-full mt-10 mb-2"
  >
    <div class="flex flex-col gap-5 w-[425px] mt-40 ml-20">
      <h4>Gebietsauswahl</h4>
      <DropDownMultipleGrouped
        :model-value="municipalitySelectItems"
        class="w-full"
        :items="municipalitySelectItems"
        placeholder="Gemeinden auswählen"
        :max-preview-items="1"
        data-key="name"
        value-key="value"
        label="Gebiet"
        item-type-all="Gemeinden"
        @update::model-value="updateAnalysis('municipalitySelectItems', $event)"
      />
    </div>
    <div class="w-[460px] h-[550px]">
      <GeoMunicipalityChart
        v-if="municipalities.length > 0"
        :selected="selected"
        :geo-items="municipalities"
        @geo-unselected="setUnSelected($event)"
        @geo-selected="setSelected($event)"
      />
    </div>
  </div>
</template>
<script setup>
import DropDownMultipleGrouped from '@/components/dropDownMultipleGrouped/DropDownMultipleGrouped.vue';
import GeoMunicipalityChart from '@/components/charts/GeoMunicipalityChart.vue';
import InputEl from '@/components/input/InputEl.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import { useSyncGeoMapSelection } from '@/composables/syncGeoMapSelection';
import { ref, watch } from 'vue';

defineExpose({ populateInputs });

const {
  setSelected,
  setUnSelected,
  selected,
  municipalitySelectItems,
  municipalities,
} = await useSyncGeoMapSelection();

const emit = defineEmits(['update']);

const title = ref();
const description = ref();
const dataBasis = ref('administrative');

watch([selected, title, description], (newVal) => {
  emit('update', {
    municipalities: selected.value,
    title: title.value,
    description: description.value,
    data_basis: dataBasis.value,
  });
});

function populateInputs(potentialAnalysis) {
  selected.value = potentialAnalysis.municipalities;
  title.value = potentialAnalysis.title;
  description.value = potentialAnalysis.description;
}
</script>
