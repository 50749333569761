<template>
  <Teleport to="body">
    <VuetifyDialog v-model="computedShowModal" title="Datengrundlage">
      <template #content>
        <p class="body-1 mb-5">
          Legen Sie die Datengrundlage der Szenarien-Erstellung fest:
        </p>
        <div class="elevation-1 rounded-lg">
          <VRadioGroup v-model="potentialAnalysis.data_basis">
            <div
              class="p-5 border-b-line-disabled-neutral border-b cursor-pointer"
              @click="updateDataBasis('administrative')"
            >
              <VRadio value="administrative">
                <template #label>
                  <h5 class="ml-5">
                    Gebietsauswahl über administrative Grenzen
                  </h5>
                </template>
              </VRadio>
            </div>
            <div
              class="flex items-center p-5 cursor-pointer"
              @click="updateDataBasis('projectPipeline')"
            >
              <VRadio value="projectPipeline">
                <template #label>
                  <h5 class="ml-5">EnBW-Projektpipeline PV-Freifläche</h5>
                </template>
              </VRadio>
            </div>
          </VRadioGroup>
        </div>
      </template>
      <template #actions>
        <div class="flex justify-between w-full gap-5">
          <ButtonEl text="Abbrechen" color="color2" @click="closeModal" />
          <ButtonEl
            text="Weiter"
            icon="arrow_forward"
            :icon-right="true"
            @click="proceed"
          />
        </div>
      </template>
    </VuetifyDialog>
  </Teleport>
</template>

<script setup>
import ButtonEl from '@/components/button/ButtonEl.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import { computed, toRefs } from 'vue';

const props = defineProps({
  showModal: Boolean,
  potentialAnalysis: Object,
});

const emit = defineEmits([
  'update:showModal',
  'update:showForm',
  'update:potentialAnalysis',
]);

const { potentialAnalysis } = toRefs(props);

const computedShowModal = computed({
  get: () => props.showModal,
  set: (value) => emit('update:showModal', value),
});

function closeModal() {
  emit('update:showModal', false);
}

function proceed() {
  emit('update:showModal', false);
  emit('update:showForm', true);
}

function updateDataBasis(value) {
  potentialAnalysis.value.data_basis = value;
  emit('update:potentialAnalysis', potentialAnalysis.value);
}
</script>
