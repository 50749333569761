<template>
  <div>
    <!-- mx-2 because of p-2 at below VTabsWindowItem -->
    <div v-if="showTabHeaders" class="border-b-[6px] border-[#968F89] mx-2">
      <VTabs
        v-model="activeTab"
        class="mb-[-6px] text-neutral"
        density="comfortable"
        :slider-color="activeColor"
      >
        <VTab
          v-for="tab in tabs"
          :key="tab"
          :value="tab"
          class="px-5 normal-case tracking-normal"
          :ripple="false"
        >
          <div
            class="flex gap-2 items-center justify-center"
            :class="
              activeTab.title === tab.title
                ? 'text-button-primary-default-color1'
                : 'text-neutral'
            "
          >
            <IconWrapper
              v-if="tab.icon"
              :icon="tab.icon"
              :size="16"
              :type="tab.iconType"
              class="relative bottom-[1px]"
            />
            <div class="subtitle-2">
              {{ tab.title }}
            </div>
          </div>
        </VTab>
      </VTabs>
    </div>
    <VTabsWindow
      v-model="activeTab.title"
      class="mt-4"
      :class="customWindowClasses"
    >
      <template v-for="(tabContent, index) in tabContents" :key="index">
        <VTabsWindowItem
          :transition="!disableTransitions"
          :reverse-transition="!disableTransitions"
          :value="tabContent.value"
          class="vtab-item-custom"
          :eager
        >
          <slot :name="tabContent.slotName" />
        </VTabsWindowItem>
      </template>
    </VTabsWindow>
  </div>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { computed, ref, watch } from 'vue';

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  showTabHeaders: {
    type: Boolean,
    default: true,
  },
  customWindowClasses: {
    type: String,
    default: '',
  },
  disableTransitions: {
    type: Boolean,
    default: false,
  },
  eager: {
    type: Boolean,
    default: false,
  },
});

const style = getComputedStyle(document.documentElement);
const activeColor = `rgb(${style.getPropertyValue('--button-primary-default-color1')})`;

const activeTab = ref(props.tabs[0]);

const tabContents = computed(() => {
  return Array.from({ length: props.tabs.length }, (_, index) => {
    const slotName = `slot${String(index)}`;
    return {
      value: props.tabs[index].title,
      slotName: slotName,
    };
  });
});

const emit = defineEmits(['updated:tab']);

// watch activeTab and emit event
watch(
  activeTab,
  (newVal) => {
    emit('updated:tab', newVal);
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.vtab-item-custom {
  overflow-y: auto;
  max-height: calc(100vh - 330px);
  padding: 8px;
}
</style>
