export class PotentialAnalysisDto {
  static BASE_URL = '/api/usecase-2/pv-free-project/';

  id = null;
  title = '';
  description = '';
  municipalities = null;
  min_area_ha = null;
  max_count_parcels = null;
  orientation_value_greater_equal_than = null;
  orientation_value_smaller_equal_than = null;
  slope_degree_value_greater_equal_than = null;
  slope_degree_value_smaller_equal_than = null;
  max_load_for_grid_connection_point_mw = null;
  max_load_for_substation_mw = null;
  battery_capacity_proportion = null;
  routing_option = null;
  disadvantage_area_list = null;
  agricultural_plots_list = null;
  specific_plant_size_mw_ha = null;
  dc_ac_ratio = null;
  inverter_efficiency = null;
  performance_ratio = null;
  mounting_slope_degree = null;
}
